import React from 'react'
import { trackClick } from '../util/AnalyticsUtil'
import { menuItems } from './DesktopHeaderMenu01'

export const LeftHpeMobileMenuButton01 = () => {
  return (
    <div className="header-left-menu-hpe d-flex d-md-none align-items-center">
      <button className="navbar-toggler" type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
    </div>
  )
}

export const LeftHpeMobileMenuItems01 = ({ mintingIsLive }: {
  mintingIsLive: boolean
}) => {
  return (
    <div className="container-fluid">
      <div className="collapse navbar-collapse header-collapse-hpe" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {menuItems(mintingIsLive).map(v => (
            <li key={v.id} className="nav-item">
              <a className="nav-link" href={(v.url == null ? "#" + v.id : v.url)} onClick={() => {
                trackClick('btn_nav_m_' + v.id)
              }}>{v.txt}</a>
            </li>))}
        </ul>
      </div>
    </div>
  )
}
