import React from 'react'
// import 'mdb-react-ui-kit/dist/css/mdb.min.css' // this applies to the entire app, which is not good for us right now
import '../style/MdbWrapper.scss';
import { MDBBtn, MDBCheckbox, MDBContainer, MDBInput } from 'mdb-react-ui-kit'

const AgostoLogin = () => {
  const MForm = () => (
    <form>
      {/* Email input */}
      <MDBInput className="mb-4" label='Correo ó Usuario' id='typeEmail' type='email' />
      
      {/* Password input */}
      <MDBInput className="mb-4" label='Contraseña' id='typePassword' type='password' />

      {/* 2 column grid layout for inline styling */}
      <div className="row mb-4">
        <div className="col d-flex justify-content-center">
          {/* Checkbox */}
          <MDBCheckbox name='flexCheck' value='' id='flexCheckChecked' label='Recordar' defaultChecked />
        </div>
        <div className="col">
          {/* Simple link */}
          <a href="#!">Forgot password?</a>
        </div>
      </div>
      {/* Submit button */}
      {/* Al parecer btn-block sirve para que el botón tome todo el ancho disponible (ancho del padre) */}
      <MDBBtn className="btn-block mb-4" type='submit'>Login</MDBBtn>
      {/* Register buttons */}
      <div className="text-center">
        <p>Not a member? <a href="#!">Register</a></p>
        <p>or sign up with:</p>
        <button type="button" className="btn btn-primary btn-floating mx-1">
          <i className="fab fa-facebook-f" />
        </button>
        <button type="button" className="btn btn-primary btn-floating mx-1">
          <i className="fab fa-google" />
        </button>
        <button type="button" className="btn btn-primary btn-floating mx-1">
          <i className="fab fa-twitter" />
        </button>
        <button type="button" className="btn btn-primary btn-floating mx-1">
          <i className="fab fa-github" />
        </button>
      </div>
    </form>
  );
  return (
    <div className="mdbWrapperElite">
      <MDBContainer>
        <MForm></MForm>
      </MDBContainer>
    </div>
  )
}

export default AgostoLogin
