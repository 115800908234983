// Import Swiper React components


// Import Swiper styles
// import '@swiper/swiper-bundle.css';

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
import 'swiper/swiper-bundle.css';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);


const PageTestingSwiper = () => {
  return (
    <Swiper
      // freeMode={true}
      spaceBetween={30}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}

      autoplay={{
        delay: 2500,
        disableOnInteraction: false
      }}
      loop={true} 
    >
      <SwiperSlide>Slide 1</SwiperSlide>
      <SwiperSlide>Slide 2</SwiperSlide>
      <SwiperSlide>Slide 3</SwiperSlide>
      <SwiperSlide>Slide 4</SwiperSlide>
      <SwiperSlide>Slide 5</SwiperSlide>
      <SwiperSlide>Slide 6</SwiperSlide>
      <SwiperSlide>Slide 7</SwiperSlide>
    </Swiper>
  );
};

export default PageTestingSwiper;
