import React, { useEffect } from 'react'


const ShPageNftcalendar = () => {
  useEffect(() => {
    window.location.href = "https://humanpetelon.com?utm_source=nftcalendario&utm_medium=upcoming&utm_campaign=20210920";
  }, [])
  return (
    <div>
      Loading the awesomeness... 😼
    </div>
  )
}

export default ShPageNftcalendar
