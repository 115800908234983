import React from 'react'
import { Link } from 'react-router-dom'

const ProvisionalNavigator = () => {
  return (
    <nav>
      Barra de navegación provisional
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li><Link to="/testing-home">Testing</Link></li>
        <li><Link to="/login-test">login-test (copiado, en caso necesitarlo luego)</Link></li>
      </ul>
    </nav>
  )
}

export default ProvisionalNavigator
