import React from 'react'
import { trackClick } from '../util/AnalyticsUtil'
import RowSpacer from './RowSpacer'

interface MenuItemContent {
  id: string, // for analytics and id (in case no url is set)
  txt: string,
  url?: string,
}

export const menuItems = function (mintingIsLive: boolean) {
  const r : MenuItemContent[] = [
    // {
    //   'id': 'what-is-it',
    //   'txt': 'intro',
    //   // 'url': '/#what-is-it',
    // },
    // {
    //   'id': 'help-us',
    //   'txt': 'help us',
    //   // 'url': '/#help-us',
    // },
    // {
    //   'id': 'roadmap',
    //   'txt': 'roadmap',
    //   'url': '/#roadmap',
    // },

  ];
  // if (mintingIsLive) {
  //   r.push(
  //     {
  //       'id': 'buy',
  //       'txt': 'buy',
  //       'url': '/buy',
  //     },
  //   );
  // }
  return r;
};

const EraDesktopHeaderMenu01 = ({ mintingIsLive }: {
  mintingIsLive: boolean
}) => {
  return (
    <>
      {/* // use fixed-top in case you want to fix to the top, 
    // note: in that case you must leave space for it above the icon */}
      <div className="container-fluid fixed-top">
        <div className="row eraDesktopHeader" >
          <p className="p-0 m-0 cont d-none d-md-inline-flex justify-content-end">
            {menuItems(mintingIsLive).map(v => (
              <a key={v.id} href={(v.url == null ? "#" + v.id : v.url)} onClick={() => {
                trackClick('btn_nav_' + v.id)
              }}>
                {v.txt}
              </a>))}
          </p>
        </div>
      </div>

      {/* in case of fixed-top: */}
      <div className="d-none d-md-block container">
        <RowSpacer height={30}></RowSpacer>
      </div>

    </>
  );
}

export default EraDesktopHeaderMenu01
