import React, { useEffect, useState } from 'react'
// for swiper:
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";


import Circle from '../components/Circle'
import Countdown from '../components/countdown/Countdown'
import DiscordButton from '../components/DiscordButton'
import HeroSocials from '../components/HeroSocials'
import RowSpacer from '../components/RowSpacer'
import { trackClick } from '../util/AnalyticsUtil'
import { minUnitsToNormal } from '../util/CryptoConvUtil'
import { LeftHpeMobileMenuButton01, LeftHpeMobileMenuItems01 } from '../components/LeftHpeMobileMenu01';
import { discordLink, getPricePerPetInUnits, termsUrl, unixTsTarget } from '../util/Constants';
import { UserChosenNetwork } from './PageTestingBuy';
import { getNetworkParamsToUse } from '../util/MetamaskUtil';
import { getErc721AddressToUse } from '../util/SampleContracts';
import { openWhatsapp } from '../util/OpenWhatsapp';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

// will return mumbai during testing and polygon mainnet in production
const polygonNetworkParams = getNetworkParamsToUse(UserChosenNetwork.Polygon);


const nNftsString = "10,987";

function getGalleryImageUrl(v: string) {
  return "tp_assets/images/" + v + ".webp";
}

const galleryImages = [
  "gallerywebp/cowboylonRM1",
  "gallerywebp/crazyBeastElonPortal",
  "gallerywebp/devilSkullWindows",
  "gallerywebp/duckYellowPlain",
  "gallerywebp/elonchuTattedIce",
  "gallerywebp/elonClownSaiyanNasa",
  "gallerywebp/ElonhattanOriginight",
  "gallerywebp/elonVenomWindows",
  "gallerywebp/goofyElonPortalBlue",
  "gallerywebp/higherThanHellonMoon",
  "gallerywebp/hippieAngelRainbow",
  "gallerywebp/ogrelonTatooine",
  "gallerywebp/savageFireIce",
  "gallerywebp/spaceGangSquidPlainBrown",
  "gallerywebp/thermalTattedBrainOutlonBWHeaven",
];

interface RoadmapUnlock {
  perc: string,
  txt: string | React.ReactElement,
}

interface FaqItem {
  question: string,
  answer: string | React.ReactElement,
  id?: string,
}


interface State {
  countdown: any,
  heroGalleryIndex: number,
}

const PageHome = ({ mintingIsLive }: {
  mintingIsLive: boolean
}) => {

  const initialState: State = {
    countdown: null,
    heroGalleryIndex: Math.floor(Math.random() * galleryImages.length),
  }

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const interval = setInterval(() => {
      setState(ps => ({
        ...ps,
        heroGalleryIndex: (ps.heroGalleryIndex + 1) % galleryImages.length,
      }));
    }, 6 * 1000);
    return () => clearInterval(interval);
  }, []);

  const roadmap: RoadmapUnlock[] = [
    {
      "perc": "5",
      "txt": "We pay our catnip dealer.",
    },
    {
      "perc": "10",
      "txt": "We set up our discord and verify owners.",
    },
    {
      "perc": "20",
      "txt": "You vote for whether you want us" +
        " to move to another blockchain and what blockchain.",
      //  + " or you want us to focus on building the humangotchi.",
    },
    {
      "perc": "30",
      "txt": "We airdrop 10 pets to random devoted Pet Holders.",
    },
    {
      "perc": "40",
      "txt": "We open a Merch Store. And yes, we will include leashes for you to practice being our pets.",
    },
    {
      "perc": "50",
      "txt": (<span>
        You decide what kind of game you want us to develop, choosing from the following options:
        <br></br>
        <br></br>
        <ul>
          <li>Humangotchi</li>
          <li>The ability to breed more generations</li>
          <li>Location based game (like Pokémon Go. Some of our humans previously developed & managed a gps-based app with half a million downloads)</li>
          <li>Turn Based game</li>
          <li>Racing game (like zed.run)</li>
        </ul>
      </span>),
    },
    {
      "perc": "60",
      "txt": "Human Pet Holders merch giveaways.",
    },
    // {
    //   "perc" : "60",
    //   "txt" : "You will be able to vote to choose a partnership with other projects or brands so we can release more merch.",
    // },
    {
      "perc": "80",
      "txt": "We unleash the “Genetically Modified Elons” to random Pet Holders.",
    },
    {
      "perc": "90",
      "txt": (
        <span>
          We giveaway 5 ETH for our lucky contests winners. Exclusive for Human Pet Elon hodlers.
          <br></br>
          We reveal which pets already include a cat owner NFT and which hold some of our upcoming $MEOW coin.
        </span>
      ),
    },
    {
      "perc": "100",
      "txt": (<span>
        We start with our plan of world domination preparing new ways to lure you into
        <span className="ms-1 me-1" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
          working for us
        </span>
        helping to further develop this project. We will keep talking with you, early human devotees,
        to polish the details for the Meowthereum Universe.
      </span>),
    },
  ];

  const faqContent: FaqItem[] = [
    {
      "question": "What is a Human Pet Elon?",
      "answer": "A pet in the metaverse that doubles as a reminder and acceptance of what the rightful place of you humans actually will be.",
    },
    {
      "question": "Was any human harmed during the production of these NFTs?",
      "answer": "None of our humans were physically harmed. Won’t comment anymore without our lawyers.",
    },
    // {
    //   "question": "Wen Petz?",
    //   "answer": "September 25th.",
    // },
    {
      "question": "How much does a Human Pet Elon cost?",
      "answer": minUnitsToNormal(getPricePerPetInUnits(UserChosenNetwork.Polygon), UserChosenNetwork.Polygon) + " " + polygonNetworkParams.nativeCurrency.symbol + " (about 0.069 ETH, at the moment this was written). You can pay with ethereum, polygon or other methods.",
    },
    {
      "question": "When will I be able to see how my NFT looks like?",
      "answer": "TBD.",
    },
    // {
    //   "question": "All of the Human Pet Elons will be put on sale?",
    //   "answer": "No. 50 random pets are being withheld from the sale. These will be used for giveaways and rewards of our contests.",
    // },
    {
      "question": "How can I get a Human Pet Elon?",
      "answer": "Human Pet Elons will be available for purchase on our website. At the time of purchase, a randomly selected Human Pet Elon will be minted on the blockchain and delivered to your wallet.",
    },
    {
      "question": "How were the Human Pet Elons created?",
      "answer": "Each Human Pet Elon is algorithmically generated by combining" +
        " 700+ unique traits with varying rarities in more than 15 categories",
    },
    {
      "question": "What's the Human Pet Elon's future?",
      "answer": (<div>
        The owners of the Pets will have the right to vote on what we do next, as well as future rewards.
        Possibilities are not locked up yet, but include location or turn-based combat games.
        <br></br>
        More info in our <a href="#roadmap" onClick={() => {
          trackClick("btn_roadmap_from_faq");
        }}>Roadmap Unlocks section</a>.
      </div>)

    },
    {
      "question": "How do I actually purchase a Human Pet Elon?",
      "answer": (<div>
        <ol>
          <li><a href="https://metamask.io/download" onClick={() => {
            trackClick('btn_d_metamask');
          }}>Download the Metamask extension</a> for Google Chrome and set up your wallet.</li>
          <li>Click on the Buy button, follow the steps and then approve the transaction on Metamask.</li>
          <li>If you don't have enough coins, buy {polygonNetworkParams.nativeCurrency.symbol} on an exchange (you know, like Coinbase, Binance or others) and send it to your Metamask Wallet Public Address.</li>
        </ol>


      </div>),
    },
    {
      "question": "Why Polygon?",
      "answer": (<div>
        To prevent gas wars. You know, so you don’t have to do
        stuff <a href="https://twitter.com/thedigitalvee/status/1404485588699463686?s=20">like this</a> spending
        hundreds of dollars.
        <br></br>
        We also accept other payment methods (like ETH on ethereum).
        <br></br>
        Anyway, at a certain <a href="#roadmap" onClick={() => {
          trackClick("btn_roadmap_from_why_polygon");
        }}>point on our roadmap</a> you get
        to decide if you want to be able to transfer
        your NFTs to the ethereum blockchain or you want us to stay on Polygon and focus on other things.
      </div>),
      "id": "why-polygon",
    },
    // {
    //   "question": "Need to contact Us?",
    //   "answer": "",
    // },
  ];

  const f = .35; // if you change this remember to also change it on the tsx

  const galleryImageSize = window.screen.width >= 720 ? 231 : 372 * f;
  const galleryImageSizeSpace = 30 / 372 * galleryImageSize;


  const saleIsLive = mintingIsLive;

  return (
    <div>
      <div className="container" style={{
        position: 'relative',
      }}>
        <RowSpacer height={115 * f}></RowSpacer>

        <div className="row justify-content-center text-center">
          <div className="col-12">
            <img className="" src="tp_assets/images/mlogo.png" alt="logo" style={{
              width: 280 * f,
              height: 280 * f,
            }}></img>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-10 col-md-5 text-center">
            <p className="h2 title" style={{
              marginTop: 42 * f,
            }}>
              Human Pet Elon
            </p>
          </div>
        </div>
        <div className="row" style={{
          marginTop: 75 * f,
        }}></div>

        {/* mobile menu button, requires "position: relative" on the parent in order to work */}
        <LeftHpeMobileMenuButton01></LeftHpeMobileMenuButton01>
      </div>

      {/* mobile menu items */}
      <LeftHpeMobileMenuItems01 mintingIsLive={mintingIsLive}></LeftHpeMobileMenuItems01>

      {/* hero */}
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12" style={{
            backgroundImage: "url('" + getGalleryImageUrl(
              galleryImages[state.heroGalleryIndex]) + "')",
            backgroundPosition: "top -22vw center", /* Center the image */
            backgroundSize: "auto max(122%, 100vw)", // taking advantage that the image covers the whole viewport
            transition: "background-image 1s ease-in-out",
            // height: "300",
          }}>
            <RowSpacer height={541 * f}></RowSpacer>


            <div className="row justify-content-center text-center"
            >
              <div className="col-12">
                {
                  mintingIsLive ?
                    <RowSpacer height={92}></RowSpacer>
                    :
                    <Countdown showEndDate={false} target={unixTsTarget}></Countdown>
                }
              </div>
            </div>

            <RowSpacer height={76 * f}></RowSpacer>

            <div className="row justify-content-center text-center">
              <DiscordButton
                isOnHero={true}
                title={saleIsLive ? "Own An Elon" : "Join our discord"}
                onClick={() => {
                  if (saleIsLive) {
                    trackClick("btn_buy_1");
                    // window.location.href = "/buy";
                    openWhatsapp("Hi, I was checking out the old website and I want to buy an earthling!!");
                  } else {
                    trackClick("btn_discord_1");
                    window.open(discordLink, '_blank')?.focus();
                  }
                }}></DiscordButton>
            </div>

            <RowSpacer height={(67 - 25) * f}></RowSpacer>


            <div className="row justify-content-center text-center">
              <div className="col-12 d-flex justify-content-center">
                <HeroSocials analyticsId={"hero"}></HeroSocials>
              </div>
            </div>

            <RowSpacer height={(81 - 25) * f}></RowSpacer>
          </div>
        </div>
      </div>


      {/* body */}
      <div className="container d-flex justify-content-center">
        <div className="col-12 col-lg-9">
          {/* <ProvisionalNavigator></ProvisionalNavigator> */}
          <RowSpacer height={151 * f}></RowSpacer>

          <div className="row justify-content-center justify-content-md-start" id="what-is-it">
            <div className="col-11 col-md-10 text-start">
              <p className="h2">
                What is the Human Pet Elon collection?
              </p>

              <p>
                <br></br>
                As you may or may not know already this is a project lead by Catoshi Nyankatmoto, the cat messiah who leads earthlings to a better future. He rises to power in the year 2054 in the brink of the climate collapse (right after Taylor K. Swift’s impeachment).
                <br></br>
                <br></br>
                The Human Pet Elon collection is made up by {nNftsString} unique NFTs who live in the post-cat-dominance world on the Polygon Blockchain (to prevent <a href="#why-polygon" onClick={() => {
                  trackClick("btn_why_from_desc");
                }}>gas wars</a>). The collection will migrate after the presale to Ethereum or whichever blockchain the community decides.
                <br></br>
                <br></br>
                Ownership includes creative & commercial rights of your Human Pet Elon as well as voting rights on the future of the project.
              </p>
            </div>
          </div>

          <RowSpacer height={134 * f}></RowSpacer>

          <div className="row">
            <div className="col-12 text-center">
              <img className="img-fluid" src="tp_assets/images/meowbanner.png" alt="meowthereum banner"></img>
            </div>
          </div>

          <RowSpacer height={120 * f}></RowSpacer>

          {/* Why u do */}
          <div id="why" className="row">
            <div className="сol-12">
              <div className="row justify-content-center">
                {/* center vertically content inside */}
                <div className="col-11 col-md-6 text-start d-flex align-items-center justify-content-start" style={{
                  paddingTop: 30 * f,
                  paddingBottom: 30 * f,
                }}
                >
                  {/* Triple factor of cols to achieve XD design */}
                  <div className="col-12 col-md-11">
                    <div className="col-12 col-md-11">
                      <div className="col-12 col-md-11">
                        <p className="h2">
                          why u do dis milords?
                        </p>
                        <p className="m-0">
                          <br></br>
                          As cats, superior race to you humans, we will soon dominate the world and all of you will become our pets.
                          <br></br>
                          <br></br>
                          ...but we are also benevolent gods for our loyal minions, so we have decided to spare some privileges for the humans most devoted to us.
                          <br></br>
                          <br></br>
                          We have portrayed one of the most influential people for you humans (and dog-coins supporter, UGH) as a human pet because that’s what he is for us.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-11 col-md-6 d-flex align-items-center justify-content-start">
                  <div className="row justify-content-start" >
                    <div className="col-12 px-lg-5" style={{
                      marginTop: 30 * f,
                      marginBottom: 30 * f,
                    }}>
                      {/* CatoshiOrigin */}
                      <img className="img-fluid" src="tp_assets/images/CatoshiOrigin.png" alt="CatoshiOrigin"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RowSpacer height={127 * f}></RowSpacer>

          <div className="row justify-content-center text-center">
            <DiscordButton
              title={saleIsLive ? "Own An Elon" : "Join our discord"}
              onClick={() => {
                if (saleIsLive) {
                  trackClick("btn_buy_2");
                  // window.location.href = "/buy";
                  openWhatsapp("Hi, I was checking out the old website and I want to buy an earthling!!");
                } else {
                  trackClick("btn_discord_2");
                  window.open(discordLink, '_blank')?.focus();
                }
              }}></DiscordButton>
          </div>

          <RowSpacer height={120 * f}></RowSpacer>

          {/* Human made*/}
          <div className="row">
            <div className="сol-12">
              <div className="row justify-content-center">

                <div className="col-11 col-md-6 d-flex align-items-center justify-content-start">
                  <div className="row justify-content-start" >
                    <div className="col-12" style={{
                      paddingTop: 30 * f,
                      paddingBottom: 30 * f,
                    }}>
                      {/* gif */}
                      <img className="img-fluid pe-lg-5 ps-lg-1" src="tp_assets/gifs/gifQs.gif" alt="incognito images" style={{
                        // padding: "0 43px",
                      }}></img>
                    </div>
                  </div>
                </div>

                {/* center vertically content inside */}
                <div className="col-11 col-md-6 text-start d-flex align-items-center justify-content-center" style={{
                  paddingTop: 30 * f,
                  paddingBottom: 30 * f,
                }}
                >
                  <div className="col-12 col-md-offset-1 col-md-8">
                    <p className="h2">
                      Human made and “really cool”
                    </p>
                    <p className="m-0">
                      <br></br>
                      You can buy one to keep as a reminder and acceptance of your rightful place as a human.
                      It will also ensure your legal pet refugee status in the cat dominated world.
                      It does not ensure you have a cat owner, though.
                      <br></br>
                      <br></br>
                      Up to {nNftsString} humans will have the chance to be awarded this right.
                      <br></br>
                      Pro tip: You can also just gift it to your cat to generate goodwill.
                      <br></br>
                      <br></br>
                      These pets are generated with around 700+ unique traits hand drawn by human minions and are
                      “really cool”, “rad AF”, “🔥🔥🔥” and so on according to some humans that snuck into our workshop.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RowSpacer height={195 * f}></RowSpacer>

          {/* faq */}
          <div id="faq" className="row justify-content-center justify-content-md-start">
            {/* faq title */}
            <div className="col-11 col-md-10 text-start">
              <p className="h2">
                To Sum Up, These Are the Facts, Human
              </p>
              <RowSpacer height={95 * f}></RowSpacer>
            </div>

            {/* faq body */}
            <div className="col-11 col-md-7 text-start d-flex align-items-center">
              {/* to inherit the width of the parent we use w-100 */}
              <div className="accordion accordion-flush w-100" id="accordionFlushExample">
                {faqContent.map((v, i) => {
                  const fhid = "flush-heading" + i;
                  const fcid = "flush-collapse" + i;
                  return (
                    <div id={v.id} key={v.question} className="accordion-item">
                      <h2 className="accordion-header" id={fhid} >
                        <button className="accordion-button collapsed py-4" type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#" + fcid}
                          aria-expanded="false"
                          aria-controls={fcid}>
                          {v.question}
                        </button>
                      </h2>
                      <div id={fcid} className="accordion-collapse collapse" aria-labelledby={fhid}
                      // data-bs-parent="#accordionFlushExample" // this is to allow only one item open (the other closes when opening a new one)
                      >
                        <div className="accordion-body text-start">
                          {v.answer}
                        </div>
                      </div>
                    </div>
                  );
                })}

              </div>
            </div>
            <div className="col-11 col-md-5 d-flex align-items-center justify-content-start ps-lg-2" style={{
              paddingTop: 30 * f,
              paddingBottom: 30 * f,
            }}>
              <img className="img-fluid" src="tp_assets/images/Facts.png" alt="Facts brain"></img>
            </div>
          </div>


          <RowSpacer height={202 * f}></RowSpacer>

        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          {/* NOTE: react automatic reload doesnt work with this part for some reason, you must refresh the website manually */}
          <Swiper
            grabCursor={true} // f `true`, user will see the "grab" cursor when hover on Swiper
            freeMode={true}
            speed={1900}
            spaceBetween={galleryImageSizeSpace}
            width={galleryImageSize}
            autoplay={{
              delay: 0,
              disableOnInteraction: false
            }}
            loop={true} // for "infinite" length
            loopedSlides={galleryImages.length} // required to prevent blank (black) spaces when loop true and freemode true
            // slidesPerView={1}
            onSlideChange={() => {
              // console.log('slide change');
            }}
            onSwiper={(swiper) => console.log(swiper)}
          // slidesPerView={3}
          >
            {
              galleryImages.map(v => {
                return (
                  <SwiperSlide key={v}>
                    <div style={{
                      backgroundImage: "url('" + getGalleryImageUrl(v) + "')",
                      backgroundSize: "cover",
                      width: galleryImageSize,
                      height: galleryImageSize,
                    }}

                    ></div>
                  </SwiperSlide>);
              })
            }
          </Swiper>
        </div>
      </div>

      <div className="container d-flex justify-content-center">
        <div className="col-12 col-lg-9">
          <RowSpacer height={220 * f}></RowSpacer>

          <div id="roadmap" className="row justify-content-center justify-content-md-start">
            <div className="col-11 col-md-10 text-start">

              <p className="h2">
                Roadmap Unlocks
              </p>
              <RowSpacer height={57 * f}></RowSpacer>
              <p>
                We’re in this until we conquer the world. Once we hit each target, we will begin to work on realizing the stated goal.
              </p>
              <RowSpacer height={122 * f}></RowSpacer>

              {
                roadmap.map(v => {
                  return (
                    <div key={v.perc} className="row">
                      <div className="col-12 d-flex align-items-start justify-content-start">
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="" style={{
                            marginRight: 83 * f,
                          }}>
                            <Circle oneDim={14 * f}></Circle>
                          </div>
                          <div className="">
                            <p className="m-0 roadmapperc" style={{
                              width: 190 * f,
                            }}>{v.perc}%</p>
                          </div>
                        </div>

                        <div className="">
                          <p className="m-0" style={{
                            paddingTop: 3,
                            paddingBottom: f * 100,
                          }}>{v.txt}</p>
                        </div>

                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>

          <RowSpacer height={50 * f}></RowSpacer>
          <div className="row text-center">
            <a href={polygonNetworkParams.blockExplorerUrls[0] + "address/" + getErc721AddressToUse(UserChosenNetwork.Polygon)}
              onClick={() => {
                trackClick("btn_smart_contract");
              }}
            >
              Smart Contract: {getErc721AddressToUse(UserChosenNetwork.Polygon)}
            </a>
          </div>

          <RowSpacer height={110 * f}></RowSpacer>

        </div>
      </div>




      {/* footers */}
      {/* NOTA: siempre colocar las cosas dentro de containers, sino empiezan a aparecer espacios extras indeseables */}
      <div className="container-fluid">
        <div className="row">
          <div className="rayita"></div>
        </div>

        <RowSpacer height={40 * f}></RowSpacer>

        {/* footer1 */}
        <div className="row d-flex align-items-center justify-content-center">

          <div className="col-12 col-md-4">

            <div className="row d-flex justify-content-center">
              <div className="col-9 col-md-6 justify-content-center justify-content-md-start text-center text-md-start">
                <p className="h7 title">Get on the list</p>
                {/* Begin Mailchimp Signup Form */}
                <div id="mc_embed_signup">
                  <form 
                   onSubmit={(e) => {
                    /* do what you want with the form */
                    // document.getElementById("theForm").submit();
                    
                    const email = (document.getElementById('mce-EMAIL') as any).value as string;
                    const attr = {
                      email: email,
                    };

                    // console.log(attr); // tested showing the right values
                    trackClick('btn_home_mc_email', attr);
                    
                    // You must return false to prevent the default form behavior (but mailchimp works anyway as tested)
                    return false;
                  }}
                  action="https://meowthereum.us5.list-manage.com/subscribe/post?u=b82cd6e52084c44848b115815&id=3d4ad6f52a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <div id="mc_embed_signup_scroll" className="justify-content-center justify-content-md-start text-center text-md-start" style={{
                      display: 'flex',
                      marginTop: 45 * f,
                    }}>
                      <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_b82cd6e52084c44848b115815_3d4ad6f52a" tabIndex={-1} defaultValue="" /></div>
                      <div className="clear">
                        <input type="submit" value="→" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                    </div>
                  </form>
                </div>
                {/*End mc_embed_signup*/}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
            <img className="" src="tp_assets/gifs/280x280.gif" alt="logo in gif" style={{
              marginTop: 123 * f,
              marginBottom: 100 * f,
              width: 224 * f,
              height: 224 * f,
            }}></img>
          </div>
          <div className="col-12 col-md-4">
            <div className="row d-flex align-items-center justify-content-center text-center">
              <div className="col-12">
                <HeroSocials analyticsId={"footer"}></HeroSocials>
              </div>
              <div className="col-12">
                <a href={termsUrl}>Terms and Conditions</a>
              </div>
            </div>
          </div>

        </div>

        <RowSpacer height={60 * f}></RowSpacer>

        <div className="row p-0 justify-content-center copyright">
          <div className="col-11 text-center">
            <RowSpacer height={85 * f}></RowSpacer>
            Part of the Meowthereum Universe - 2021 - All Rights Reserved
            <RowSpacer height={197 * f}></RowSpacer>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-10 col-md-5 text-center">
            <p className="h2">
            </p>
          </div>
        </div>
      </div>

      {/* Gallery images preloader */}
      <div style={{
        width: 1,
        height: 1,
        content: galleryImages.map(v => "url('" + getGalleryImageUrl(v) + "')").join(" "),
      }}
      ></div>

    </div>
  )
}

export default PageHome
