import React, { useEffect } from 'react'

const testingRedirUrl = "https://humanpetelon.com?utm_source=test&utm_medium=test01&utm_campaign=20210920";

const ShPageRarity = () => {
  useEffect(() => {
    window.location.href = "https://humanpetelon.com?utm_source=rarity&utm_medium=upcoming&utm_campaign=20210920";
    // window.location.href = testingRedirUrl;
  }, [])
  return (
    <div>
      Loading the awesomeness... 😼
    </div>
  )
}

export default ShPageRarity
