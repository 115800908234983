import { MDBBtn, MDBContainer, MDBInput } from 'mdb-react-ui-kit'
import SwipeableViews from 'react-swipeable-views';
import smartlookClient from 'smartlook-client'
import '../style/MdbWrapper.scss';
import { useEffect, useState } from 'react'
import { transactionErrored, TransactionStatus, useContractFunction, useEtherBalance, useEthers, useSendTransaction } from '@usedapp/core';
import { UnsupportedChainIdError } from '@web3-react/core';
import { checkCurrentNetwork, getEthereum, getNetworkParamsToUse, getNetworkToUseChainId, metamaskCheckNetworkAndTryToChangeIt, noMetamaskAlertText } from '../util/MetamaskUtil';
import * as Sentry from "@sentry/react";
import {
  UserRejectedRequestError,
} from '@web3-react/injected-connector';
import M1Modal from '../components/M1Modal';
import { minUnitsToNormal } from '../util/CryptoConvUtil';
import { TransactionStatusRC } from '../components/M1TransactionStatus';
import { useErc721Balance } from '../util/UseDappUtil';
import M1Balance from '../components/M1Balance';
import { catoshiPublicAddress, getPricePerPetInUnits, getSuggestedGasPrice, maxPetsPerTr, pricePerPetInUsd, suggestedGasPerNft, termsUrl } from '../util/Constants';
import { AnalyticsParamsType, trackClick } from '../util/AnalyticsUtil';
import { fa } from '../util/FirebaseInit';
import { hex0xStringToBase64 } from '../util/ConversionUtil';
import { LeftHpeMobileMenuButton01, LeftHpeMobileMenuItems01 } from '../components/LeftHpeMobileMenu01';
import { fbqRecordEvent } from '../util/FbPixel';
import Rectangle from '../components/Rectangle';
import RowSpacer from '../components/RowSpacer';
import { getCommonNameOfNetwork, getErc721AddressToUse } from '../util/SampleContracts';
import { getHpeContract } from '../abi/hpe';


let lastTxHashProcesed = "";
// let lastTxHashProcesed = -1;
let lastAccount = "";

export enum UserChosenNetwork {
  Ethereum,
  Polygon,
}

interface State {
  n_pets?: number | null,

  swipePageIndex: number,

  user_chosen_network?: UserChosenNetwork,

  // TODO: build a reactive popup showing structure
  // so we can check all the state whenever we want
  // and so update the view without
  // having to rely on results (ie: having to query if wallet is connected, 
  // if network is correct, ...)
  showing_other_pmt_method: boolean,

  showing_metamask_not_installed: boolean,

  showing_change_network_modal: boolean,
  changing_network: boolean,

  showing_connect_wallet_modal: boolean,
  connecting_wallet: boolean,

  showing_buy_confirmation_modal: boolean,

  // We need to manually track it.
  // We tried: !(transactionErrored(ts) || ts.status === 'Success' || ts.status === 'None') &&
  // but is not enough as it doesnt take into account the 
  // phase when the user is signing the transaction
  transaction_in_progress: boolean,

  showing_buy_result_modal: boolean,

  max_exceeded: boolean,

  last_n_pets_that_tried_to_buy: number,

  otherPmtMethod: string,
}

const PageTestingBuy = ({ mintingIsLive }: {
  mintingIsLive: boolean
}) => {
  const initialState: State = {
    swipePageIndex: 0,

    showing_other_pmt_method: false,

    showing_metamask_not_installed: false,

    changing_network: false,
    showing_change_network_modal: false,

    connecting_wallet: false,
    showing_connect_wallet_modal: false,

    showing_buy_confirmation_modal: false,
    transaction_in_progress: false,

    max_exceeded: false,

    showing_buy_result_modal: false,

    last_n_pets_that_tried_to_buy: 0,

    otherPmtMethod: "",
  }
  const [state, setState] = useState(initialState);

  const { activateBrowserWallet, account, error, chainId } = useEthers();
  const cryptoBalance = useEtherBalance(account);

  // transaction
  const contractFunction01 = useContractFunction(getHpeContract(UserChosenNetwork.Polygon), 'grabManyHpes',
    // { transactionName: 'Wrap' }
  );
  // { state, send }

  const _useSendTransaction = useSendTransaction({ transactionName: 'Send Ethereum' });
  const ethereumTransferState = _useSendTransaction.state;

  useEffect(() => {
    document.title = "Buy | Human Pet Elon";
  });

  function onTransactionStateChange(trState: TransactionStatus) {
    // only when we change to error or to success:
    if (trState.status === 'Success' || transactionErrored(trState)) {

      // making sure we don't process the same transaction twice or more:
      const curTxHash = trState.receipt?.transactionHash ?? "";
      // const curTxHash = trState.transaction?.nonce ?? 0;

      if (lastTxHashProcesed !== curTxHash || curTxHash === "") {
        // we allow the empty string because we need to store errors of unfinished transactions
        // and we don't care about their idempotency, just the idempotency of 'purchase' is
        // important
        lastTxHashProcesed = curTxHash;

        if (trState.status === 'Success') { // curTxHash is not empty in this case
          toggleShowBuyResultModal(true); // but it will show only if succesful
          toggleShowConfirmationModal(false);

          const valueInUsd = pricePerPetInUsd * state.last_n_pets_that_tried_to_buy;
          buyPageLogEvent('purchase', {
            nfts: state.last_n_pets_that_tried_to_buy,
            wallet: hex0xStringToBase64(account),
            currency: "USD",
            value: valueInUsd,
            transaction_id: lastTxHashProcesed,
          });

          try {
            fbqRecordEvent('track', 'Purchase', { currency: "USD", value: valueInUsd });
          } catch (e) {
            Sentry.captureException(e);
          }

        } else if (transactionErrored(trState)) {
          buyPageLogEvent('buy_error', {
            message: trState.errorMessage?.substr(0, 98),
          });
          // TODO: test if this works:
          Sentry.captureException(trState.errorMessage);
        }
      }
    }
  }

  useEffect(() => {
    onTransactionStateChange(contractFunction01.state);
  }, [contractFunction01.state]);

  useEffect(() => {
    onTransactionStateChange(_useSendTransaction.state);
  }, [_useSendTransaction.state]);

  useEffect(() => {
    if (lastAccount !== (account ?? "")) {
      lastAccount = account ?? "";

      if (lastAccount.length > 0) {
        const walletB64 = hex0xStringToBase64(lastAccount);
        // console.log(lastAccount.toString());
        fa.setUserProperties({ wallet: walletB64 });
        buyPageLogEvent('wallet_connected', {
          wallet: walletB64,
        });
        try { // to make it work in the testing website too
          smartlookClient.properties({
            wallet_connected: true,
            wallet: walletB64
          });
          smartlookClient.track('wallet_connected', {
            wallet: walletB64,
          });
        } catch (e) {
          Sentry.captureException(e);
        }
      } else {
        buyPageLogEvent('wallet_disconnected');
      }
    }

  }, [account]);

  // listing tokens or NFTs:
  const nftBalance = useErc721Balance(getErc721AddressToUse(UserChosenNetwork.Polygon), account);

  const toggleShowOtherPmtMethod = (open: boolean) => setState(ps => ({
    ...ps,
    showing_other_pmt_method: open,
  }));

  const toggleShowMetamaskNotInstalledModal = (open: boolean) => setState(ps => ({
    ...ps,
    showing_metamask_not_installed: open,
  }));

  const toggleShowConnectWalletModal = (open: boolean) => setState(ps => ({
    ...ps,
    showing_connect_wallet_modal: open,
  }));

  const toggleShowBuyResultModal = (open: boolean) => setState(ps => ({
    ...ps,
    showing_buy_result_modal: open,
  }));

  const toggleShowChangeNetworkWalletModal = (open: boolean) => setState(ps => ({
    ...ps,
    showing_change_network_modal: open,
  }));

  const toggleShowConfirmationModal = (open: boolean) => setState(ps => ({
    ...ps,
    showing_buy_confirmation_modal: open,
  }));

  async function onConnectWalletClick() {
    const userChosenNetwork: UserChosenNetwork = state.user_chosen_network ?? UserChosenNetwork.Polygon;
    setState(ps => ({
      ...ps,
      connecting_wallet: true,
    }));
    let res = false;
    try {
      // TODO: check if metamask still installed (unlikely but don't want to be thinking about edge cases
      // when analyzing analytics results)

      // check if network is still the correct one (in case the user changed it after switching)
      const ccn = await checkCurrentNetwork(userChosenNetwork);
      if (ccn !== true) {
        buyPageLogEvent('user_switched_network_after_switching');
        alert("You must use the network: " + getNetworkParamsToUse(userChosenNetwork).chainName + " human!! 😾");
      } else {
        await activateBrowserWallet(undefined, true);
        res = true;
      }
    } catch (error) {
      if (error instanceof UnsupportedChainIdError) {
        alert("You must use the network: " + getNetworkParamsToUse(userChosenNetwork).chainName + " human!! 😾");
      } else if (error instanceof UserRejectedRequestError) {
        ; // do nothing
      } else {
        Sentry.captureException(error);
        alert(error);
      }
    }
    setState(ps => ({
      ...ps,
      connecting_wallet: false,
    }));

    if (res === true) { // wallet connected
      toggleShowConnectWalletModal(false);
      onWalletConnectedAfterBuyIntent();
    }
  }

  async function onWalletConnectedAfterBuyIntent() {
    toggleShowConfirmationModal(true);
  }

  async function onChangeNetworkClick(_userChosenNetwork: UserChosenNetwork | undefined) {
    const userChosenNetwork = _userChosenNetwork ?? UserChosenNetwork.Polygon;
    setState(ps => ({
      ...ps,
      changing_network: true,
    }));
    const r1 = await metamaskCheckNetworkAndTryToChangeIt(userChosenNetwork);
    setState(ps => ({
      ...ps,
      changing_network: false,
    }));

    if (r1 === true) { // closing dialog and going to the next step
      toggleShowChangeNetworkWalletModal(false);
      checkWalletConnectedAndIfNotShowModal();
    } else { // user rejected or other error logged to sentry
      ;
    }
  }

  async function sendBuyOrder() {
    if (!mintingIsLive) {
      alert('Your Neediness is Immeasurable. Minting is not live yet human!');
      return;
    }

    const _n = state.user_chosen_network ?? UserChosenNetwork.Polygon;

    const nativeCurrencyRequired = getTotalPriceInIntUnits(_n);

    let res = false;
    setState(ps => ({
      ...ps,
      transaction_in_progress: true,
      last_n_pets_that_tried_to_buy: (state.n_pets ?? 0),
    }));
    try {
      // next promise is finished when the transaction is confirmed by the network (finished)
      if (_n === UserChosenNetwork.Polygon) {
        const sendResult = await contractFunction01.send(state.n_pets, {
          value: nativeCurrencyRequired,
          gasLimit: ((state.n_pets ?? 20) + 1) * suggestedGasPerNft,
          gasPrice: getSuggestedGasPrice(_n),
        });
        // sendResult is undefined either when successful or not
        // console.log(sendResult);
      } else {
        // sending the ETH to catoshi
        await _useSendTransaction.sendTransaction({
          to: catoshiPublicAddress,
          value: nativeCurrencyRequired,
        });
      }

      res = true; // when the user rejects there is no exception thrown, this gets to be true
    } catch (e: any) {
      if (e.code == 4001) { // user rejected (NOTE: execution doesnt' get here)
        ;
      } else {
        Sentry.captureException(e);
        alert("Error while signing 🙀. This has been reported to Catoshi! Some humans will be whipped!");
      }
    }
    setState(ps => ({
      ...ps,
      transaction_in_progress: false,
    }));
  }

  async function checkIfMetamaskIsInstalled(userChosenNetwork: UserChosenNetwork) {
    setState(ps => ({
      ...ps,
      user_chosen_network: userChosenNetwork,
    }));

    // first checking if metamask is installed
    const ethereum = getEthereum();

    buyPageLogEvent('check_metamask_' + getAnlyticsIdForNetwork(userChosenNetwork) + "_" + (ethereum != null));
    if (ethereum == null) {
      toggleShowMetamaskNotInstalledModal(true);
    } else {
      toggleShowMetamaskNotInstalledModal(false);
      checkCurrentNetworkAndIfNotShowModal(userChosenNetwork);
    }
  }

  async function checkCurrentNetworkAndIfNotShowModal(userChosenNetwork: UserChosenNetwork) {
    const ccn = await checkCurrentNetwork(userChosenNetwork); // true if correct network, otherwise we try to change it

    buyPageLogEvent('check_network_result_' + getAnlyticsIdForNetwork(userChosenNetwork) + '_' + !(ccn !== true));
    if (ccn !== true) {
      toggleShowChangeNetworkWalletModal(true);
    } else {
      toggleShowChangeNetworkWalletModal(false);
      checkWalletConnectedAndIfNotShowModal();
    }
  }
  
  async function checkWalletConnectedAndIfNotShowModal() {
    const _n = state.user_chosen_network ?? UserChosenNetwork.Polygon;
    buyPageLogEvent('check_walletconn_result_' + getAnlyticsIdForNetwork(_n) + "_" + (account != null));
    if (account == null) {
      toggleShowConnectWalletModal(true);
    } else {
      toggleShowConnectWalletModal(false);
      onWalletConnectedAfterBuyIntent();
    }
  }

  function getNPetsValidationError() {
    const n_pets = (state.n_pets ?? 0);
    if (n_pets <= 0) {
      return "Choose more than zero";
    }
    if (n_pets !== Math.round(n_pets)) {
      return "Choose a integer number";
    }
    if (n_pets > maxPetsPerTr) {
      return "Max " + maxPetsPerTr + " at a time";
    }

    return null;
  }

  async function onBuyClick() {
    const err = getNPetsValidationError();
    if (err != null) {
      alert(err);
      return;
    }

    setState(ps => ({
      ...ps,
      swipePageIndex: 1,
    }));

    return true;
    // checkIfMetamaskIsInstalled();
  }

  function proceedToEmailStep(otherPmt: string) {
    setState(ps => ({
      ...ps,
      swipePageIndex: 2,
      otherPmtMethod: otherPmt,
    }));
  }

  const pageChangeIndexHandler = async (index: number) => {
    // what was trying didn't work as expected:
    // let newIndex = index;
    // if (index === 1) { // equivalent to buy click
    //   const res = await onBuyClick();

    //   // in case of validation error we return to step 0
    //   if (res === true) {
    //     ;
    //   } else {
    //     newIndex = 0;
    //   }
    // }

    setState(ps => ({
      ...ps,
      // swipePageIndex: newIndex,
      swipePageIndex: index,
    }));
  }

  function getTotalPrice(userChosenNetwork?: UserChosenNetwork | undefined) {
    const _n = userChosenNetwork ?? UserChosenNetwork.Polygon;
    return minUnitsToNormal(getTotalPriceInIntUnits(_n), _n)
      + " "
      + getNetworkParamsToUse(_n).nativeCurrency.symbol;
  }

  function getTotalPriceUsd() {
    const n = state.n_pets ?? 0;
    return (n * pricePerPetInUsd).toFixed(2) + " USD";
  }

  function balanceAvailable(): boolean {
    return cryptoBalance != null;
  }

  function getTotalPriceInIntUnits(userChosenNetwork: UserChosenNetwork) {
    return getPricePerPetInUnits(userChosenNetwork)
      .mul(state.n_pets ?? 0)
      ;
  }

  function notEnoughBalance(): boolean {
    const _n = state.user_chosen_network ?? UserChosenNetwork.Polygon;
    const mul = getTotalPriceInIntUnits(_n);
    return cryptoBalance?.lt(mul) ?? true;
  }

  function getCurrentTransactionState() {
    const _n = state.user_chosen_network ?? UserChosenNetwork.Polygon;
    if (_n === UserChosenNetwork.Polygon) {
      return contractFunction01.state;
    }
    return _useSendTransaction.state;
  }

  function buyPageTrackClick(btn_name: string, otherParams?: AnalyticsParamsType) {
    const _n = state.user_chosen_network;
    const ne = (_n == null ? "" : getAnlyticsIdForNetwork(_n));
    trackClick(btn_name, {
      network_chosen: ne,
      ...(otherParams ?? {})
    });
  }

  function buyPageLogEvent(event_name: string, otherParams?: AnalyticsParamsType) {
    const _n = state.user_chosen_network;
    const ne = (_n == null ? "" : getAnlyticsIdForNetwork(_n));
    fa.logEvent(event_name, {
      network_chosen: ne,
      ...(otherParams ?? {})
    });
  }

  return (
    <div>
      <div className="mdbWrapperElite">
        <MDBContainer>

          {/* <ProvisionalNavigator></ProvisionalNavigator> */}

          <div className="row justify-content-center">
            <div className="col-12 col-md-5">

              {/* image */}
              <div className="row justify-content-center mt-4 mb-4" style={{
                position: 'relative',
              }}>
                <div className="col-4">
                  <img className="img-fluid" src="tp_assets/images/Facts.png" alt="Facts brain"></img>
                </div>

                {/* mobile menu button, requires "position: relative" on the parent in order to work */}
                <LeftHpeMobileMenuButton01></LeftHpeMobileMenuButton01>
              </div>

              {/* mobile menu items */}
              <LeftHpeMobileMenuItems01 mintingIsLive={mintingIsLive}></LeftHpeMobileMenuItems01>

              <SwipeableViews
                // disabled={getNPetsValidationError() !== null} // prohibit the user from changing slides
                disabled={true}
                index={state.swipePageIndex}
                onChangeIndex={pageChangeIndexHandler}
                resistance
                enableMouseEvents
              >
                {/* Buy page: */}
                <div>
                  {/* Cant buy warning */}
                  <div
                    className="alert alert-danger alert-dismissible fade show p-0" role="alert">
                    {/* We need to add the &#8203; in the end in order for this element to occupy space (height) when the error is not showing */}
                    <p className="p-0" style={{ fontSize: 13 }}>{(mintingIsLive ? "" :
                      "You can not buy yet. Clicking 'Buy' will only guide you through setting up your polygon account.")}&#8203;</p>
                  </div>

                  <form onSubmit={(event) => {

                    // (event as any).testException();
                    event.preventDefault();
                    const n_pets = (event.target as any).n_pets.value;

                    buyPageTrackClick('btn_buy', {
                      'amount_set': n_pets,
                    });

                    onBuyClick();
                  }}>

                    <MDBInput label='Number of Human Pets' id='typeNumber'
                      type='number' name="n_pets"
                      className="mb-1 p-4 "
                      required
                      // disabled={true}
                      disabled={state.transaction_in_progress}
                      value={state.n_pets?.toString() ?? ""}
                      onChange={(e: any) => {
                        const v = parseInt(e.target.value);
                        const v2 = isNaN(v) ? null : v;
                        setState(ps => ({
                          ...ps,
                          n_pets: v2,
                          max_exceeded: (v2 ?? 0) > maxPetsPerTr,
                        }));
                      }}
                    />
                    <div id="max_banner"
                      className="alert alert-danger alert-dismissible fade show p-0" role="alert">
                      {/* We need to add the &#8203; in the end in order for this element to occupy space (height) when the error is not showing */}
                      <p className="p-0" style={{ fontSize: 13 }}>{(state.max_exceeded ? "Max " + maxPetsPerTr + " at a time" : "")}&#8203;</p>
                      {/* When changing state and having dismissed the error the next part generates a  NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.*/}
                      {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" /> */}
                    </div>

                    <p className="mt-0">Total: {getTotalPrice()} ({getTotalPriceUsd()})</p>

                    <p style={{
                      fontSize: 13,
                    }}>By clicking Buy you agree to our <a href={termsUrl} onClick={() => {
                      buyPageTrackClick('btn_terms_buy_page');
                    }}>Terms and Conditions</a></p>

                    <MDBBtn
                      className="btn-block btn-dark mb-4" type='submit'
                      disabled={state.transaction_in_progress
                        || state.max_exceeded}
                    >{state.transaction_in_progress ? "Buying..." : "Buy"}</MDBBtn>

                    <TransactionStatusRC transaction={getCurrentTransactionState()}></TransactionStatusRC>

                    <p className="h6">{(account == null || error != null ? "" : "Wallet connected ✔️ (" + account.substr(0, 6) + "..." + account.substr(account.length - 4, 4) + ")")}</p>

                    {/* Only showing NFT balance in case the user is connected to polygon (tested) */}
                    {(chainId === getNetworkToUseChainId(UserChosenNetwork.Polygon)) && (<M1Balance account={account} nftBalance={nftBalance}></M1Balance>)}

                    {/* <MDBBtn
                  className="btn-block btn-second-hpe mb-4"
                  disabled={false}
                  onClick={(e : any) => {
                    e.preventDefault();
                    buyPageTrackClick('btn_other_pmt_method');

                    toggleShowOtherPmtMethod(true);
                    
                    // const subject = 'Help with payment!';
                    // const body = 'I need help paying.';
                    // const email = 'catoshi@meowthereum.com';
                    // const url = "mailto:" + email + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
                    // window.open(url, '_blank')?.focus();
                  }}
                >Problems paying? Want to use another payment method?</MDBBtn> */}

                  </form>

                </div>

                {/* Choose payment method */}
                <div>
                  <p className="m-0 fw-bold">Order:</p>
                  <p className="m-0">Number of Pets: {state.n_pets ?? 0}</p>
                  <p className="m-0">Total: {getTotalPrice()} ({getTotalPriceUsd()})</p>
                  <p className="m-0 mt-3 fw-bold">Pay With:</p>
                  {/* weirdly the row made a horizontal scrollbar (took too much width space) */}
                  <div className="d-flex justify-content-center">
                    <div className="col-9">
                      <div className="row">

                        <MDBBtn
                          className="btn-block btn-second-hpe d-flex justify-content-start p-0 align-items-center mt-3"
                          disabled={false}
                          onClick={(e: any) => {
                            e.preventDefault();
                            buyPageTrackClick('btn_pmt_method_matic');
                            checkIfMetamaskIsInstalled(UserChosenNetwork.Polygon);
                          }}
                        >
                          {/* just to set height */}
                          <Rectangle width={0} height={40}></Rectangle>

                          <img src="tp_assets/icon/icon_matic.png" alt="icon matic" style={{
                            width: 30,
                            height: 30,
                            marginRight: 10,
                            marginLeft: 7,
                          }}></img>
                          <span className="flex-fill text-center pe-5">MATIC on Polygon</span>
                        </MDBBtn>

                        <MDBBtn
                          className="btn-block btn-second-hpe d-flex justify-content-start p-0 align-items-center mt-3"
                          disabled={false}
                          onClick={(e: any) => {
                            e.preventDefault();
                            buyPageTrackClick('btn_pmt_eth');
                            checkIfMetamaskIsInstalled(UserChosenNetwork.Ethereum);
                          }}
                        >
                          {/* just to set height */}
                          <Rectangle width={0} height={40}></Rectangle>
                          <img src="tp_assets/icon/icon_eth.png" alt="icon matic" style={{
                            width: 30,
                            height: 30,
                            marginRight: 10,
                            marginLeft: 7,
                          }}></img>
                          <span className="flex-fill text-center pe-5">ETH on Ethereum</span>
                        </MDBBtn>

                        <MDBBtn
                          className="btn-block btn-second-hpe d-flex justify-content-start p-0 align-items-center mt-3"
                          disabled={false}
                          onClick={(e: any) => {
                            e.preventDefault();
                            buyPageTrackClick('btn_pmt_othercoins');
                            proceedToEmailStep('othercoins');
                          }}
                        >
                          {/* just to set height */}
                          <Rectangle width={0} height={40}></Rectangle>
                          <img src="tp_assets/icon/icon_oc.png" alt="icon other coins" style={{
                            width: 112 * (32 / 112),
                            height: 102 * (32 / 112),
                            marginRight: 10,
                            marginLeft: 7,
                          }}></img>
                          <span className="flex-fill text-center pe-5">Other Coins</span>
                        </MDBBtn>

                        <MDBBtn
                          className="btn-block btn-second-hpe d-flex justify-content-start p-0 align-items-center mt-3"
                          disabled={false}
                          onClick={(e: any) => {
                            e.preventDefault();
                            buyPageTrackClick('btn_pmt_card');
                            proceedToEmailStep('card');
                          }}
                        >
                          {/* just to set height */}
                          <Rectangle width={0} height={40}></Rectangle>
                          <img src="tp_assets/icon/icon_card.png" alt="icon credit cards" style={{
                            width: 104 * (32 / 104),
                            height: 74 * (32 / 104),
                            marginRight: 10,
                            marginLeft: 7,
                          }}></img>
                          <span className="flex-fill text-center pe-5">Credit Card</span>
                        </MDBBtn>
                      </div>
                    </div>
                  </div>

                  <MDBBtn className="mt-4" color="none" tag="a" onClick={() => {
                    buyPageTrackClick("btn_back_s2");
                    setState(ps => ({
                      ...ps,
                      swipePageIndex: 0,
                    }));
                  }}>
                    <p className="h7 title m-0">Back</p>
                  </MDBBtn>
                </div>

                {/* Email step */}
                <div>
                  <p className="m-0 fw-bold">Order:</p>
                  <p className="m-0">Number of Pets: {state.n_pets ?? 0}</p>
                  <p className="m-0">Total: {getTotalPrice()} ({getTotalPriceUsd()})</p>
                  <p className="m-0 mt-3 fw-bold">Leave your email address to receive instructions on the payment:</p>

                  <div className="mt-3 d-flex justify-content-center">
                    <div className="col-9 col-md-6 justify-content-center text-center">
                      {/* <p className="h7">Leave your email and we will contact you</p> */}
                      {/* Begin Mailchimp Signup Form */}
                      <div id="mc_embed_signup">
                        <form
                          onSubmit={(e) => {
                            /* do what you want with the form */
                            // document.getElementById("theForm").submit();

                            const email = (document.getElementById('mce-EMAIL') as any).value as string;
                            const attr = {
                              n_pets: state.n_pets ?? 0,
                              email: email,
                              pmt_method: state.otherPmtMethod,
                            };

                            // console.log(attr); // tested showing the right values
                            buyPageTrackClick('btn_mc_email', attr);

                            // also as event to mark it as conversion so we try not to lose this data
                            // source: https://firebase.googleblog.com/2016/11/how-long-does-it-take-for-my-firebase-analytics-data-to-show-up.html
                            buyPageLogEvent('btn_mc_email', attr);

                            // You must return false to prevent the default form behavior (but mailchimp works anyway as tested)
                            return false;
                          }}
                          action="https://meowthereum.us5.list-manage.com/subscribe/post?u=b82cd6e52084c44848b115815&id=3d4ad6f52a"
                          method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
                          target="_blank"
                          noValidate>
                          <div id="mc_embed_signup_scroll" className="justify-content-center text-center" style={{
                            display: 'flex',
                            marginTop: 10,
                          }}>
                            <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_b82cd6e52084c44848b115815_3d4ad6f52a" tabIndex={-1} defaultValue="" /></div>
                            <div className="clear">
                              <input type="submit" value="→" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                          </div>
                        </form>
                      </div>
                      <RowSpacer height={150}></RowSpacer>
                      {/*End mc_embed_signup*/}
                    </div>
                  </div>


                  <MDBBtn className="mt-4" color="none" tag="a" onClick={() => {
                    buyPageTrackClick("btn_back_s3");
                    setState(ps => ({
                      ...ps,
                      swipePageIndex: 1,
                    }));
                  }}>
                    <p className="h7 title m-0">Back</p>
                  </MDBBtn>
                </div>
              </SwipeableViews>

            </div>
          </div>


          {/* Modal for other payment method */}
          <M1Modal showing_modal={state.showing_other_pmt_method} onClose={() => {
            toggleShowOtherPmtMethod(false);
          }}
            title="About the payment method"
            textContent={"Leave your email below and we will contact you:"}
            modalBody={
              (<></>
                // <div className="row d-flex justify-content-center">
                //   <div className="col-9 col-md-6 justify-content-center text-center">
                //     {/* <p className="h7">Leave your email and we will contact you</p> */}
                //     {/* Begin Mailchimp Signup Form */}
                //     <div id="mc_embed_signup">
                //       <form action="https://meowthereum.us5.list-manage.com/subscribe/post?u=b82cd6e52084c44848b115815&id=3d4ad6f52a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                //         <div id="mc_embed_signup_scroll" className="justify-content-center text-center" style={{
                //           display: 'flex',
                //           marginTop: 10,
                //         }}>
                //           <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                //           {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                //           <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_b82cd6e52084c44848b115815_3d4ad6f52a" tabIndex={-1} defaultValue="" /></div>
                //           <div className="clear">
                //             <input type="submit" value="→" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                //         </div>
                //       </form>
                //     </div>
                //     {/*End mc_embed_signup*/}
                //   </div>
                // </div>
              )}

          ></M1Modal>


          {/* Modal when metamask is not installed */}
          <M1Modal showing_modal={state.showing_metamask_not_installed} onClose={() => {
            toggleShowMetamaskNotInstalledModal(false);
          }}
            title="Metamask is not installed"
            textContent={noMetamaskAlertText}
            modalBody={
              (<>
                <MDBBtn className="btn-block mb-1" onClick={() => {
                  buyPageTrackClick("btn_ok_to_metamask_not_i");
                  toggleShowMetamaskNotInstalledModal(false);
                }}
                >
                  Ok
                </MDBBtn>
                {/* <MDBBtn className="btn-block mb-1" onClick={() => {
                  buyPageTrackClick("btn_pp_other_pmt");
                  toggleShowMetamaskNotInstalledModal(false);
                  toggleShowOtherPmtMethod(true);
                }}
                >
                  Use another payment method
                </MDBBtn> */}
              </>
              )}

          ></M1Modal>


          {/* Modal for asking to connect wallet */}
          <M1Modal showing_modal={state.showing_connect_wallet_modal} onClose={() => {
            toggleShowConnectWalletModal(false);
          }} title="Do you have enough coins, human? 😼" modalBody={
            (<>
              <MDBBtn className="btn-block mb-1" onClick={() => {
                buyPageTrackClick("btn_connect_wallet");
                onConnectWalletClick();
              }}
                disabled={state.connecting_wallet}
              >
                Connect Metamask Wallet
              </MDBBtn>
            </>
            )}
            lowerRE={
              <p className="p-0 m-0 text-center">
                {(state.connecting_wallet ? "⌛ Connecting to wallet...(wait for the Metamask notification)" : "")}&#8203;
              </p>
            }

          ></M1Modal>


          {/* Modal for asking to change network */}
          <M1Modal showing_modal={state.showing_change_network_modal} onClose={() => {
            toggleShowChangeNetworkWalletModal(false);
          }} title="Change network"
            textContent={"We need you to use the "
              + getNetworkParamsToUse(state.user_chosen_network ?? UserChosenNetwork.Polygon).chainName
              + " network. Allow us to add it and switch to that network, human 😼!"}
            modalBody={(
              <div>
                <MDBBtn className="btn-block mb-1" onClick={() => {
                  buyPageTrackClick("btn_change_network");
                  onChangeNetworkClick(state.user_chosen_network);
                }}
                  disabled={state.changing_network}
                >
                  {"Change to " +
                    getNetworkParamsToUse(state.user_chosen_network ?? UserChosenNetwork.Polygon)
                      .chainName}
                </MDBBtn>

              </div>
            )}
            lowerRE={
              <p className="p-0 m-0 text-center">
                {(state.changing_network ? "⌛ Changing network...(wait for the Metamask notification)" : "")}&#8203;
              </p>
            }
          ></M1Modal>
        </MDBContainer>


        {/* Modal for confirming the buy */}
        <M1Modal showing_modal={state.showing_buy_confirmation_modal} onClose={() => {
          toggleShowConfirmationModal(false);
        }}

          title={"You are buying "
            + state.n_pets
            + " " + (state.n_pets === 1 ? "human pet" : "human pets") + " 💎 for "
            + getTotalPrice(state.user_chosen_network)
            + ", is it ok human? 😼"}

          textContent={((state.user_chosen_network ?? UserChosenNetwork.Polygon) === UserChosenNetwork.Polygon ?
            "" :
            ""
          )}

          modalBody={(
            <div>
              <MDBBtn className="btn-block mb-1" onClick={() => {
                buyPageTrackClick("btn_send_buy_order");
                sendBuyOrder();
              }}
                disabled={state.transaction_in_progress || notEnoughBalance()}
              >
                Buy already!!
              </MDBBtn>
            </div>
          )}

          lowerRE={(
            <>
              {/* <div className="text-center">
                <TransactionStatusRC transaction={getCurrentTransactionState()}></TransactionStatusRC>
              </div> */}
              <p className="p-0 m-0 text-center">
                {
                  (getCurrentTransactionState().status === 'Mining' ? "⌛ Buying...(waiting for network confirmation)" : (
                    state.transaction_in_progress ? "⌛ Buying...(waiting for the Metamask notification)" :
                      (!balanceAvailable() ? "⌛ Querying balance..." : (
                        notEnoughBalance() ?
                          "😾 You don't have enough coins, you only have "
                          + minUnitsToNormal(cryptoBalance, state.user_chosen_network ?? UserChosenNetwork.Polygon) + " "
                          + getNetworkParamsToUse(state.user_chosen_network ?? UserChosenNetwork.Polygon).nativeCurrency.symbol
                          : ""))
                  ))
                }&#8203;
              </p>
            </>
          )}

        ></M1Modal>


        {/* Modal after a succesful buy */}
        <M1Modal
          showing_modal={getCurrentTransactionState().status === 'Success'
            && state.showing_buy_result_modal} onClose={() => {
              toggleShowBuyResultModal(false);
            }}
          title="The transaction was succesful ✔️" 
          textContent={((state.user_chosen_network ?? UserChosenNetwork.Polygon) === UserChosenNetwork.Polygon ?
            "" :
            "The NFTs will be transfered to your wallet in polygon in no more than 2 hours after payment confirmation (only during 10am to 9pm GMT-5). Otherwise one of our human minions will be whipped ✊" // ethereum
          )}
          modalBody={
            (<>
              <MDBBtn className="btn-block mb-1" onClick={() => {
                buyPageTrackClick("btn_thanks");
                toggleShowBuyResultModal(false);

                setState(ps => ({
                  ...ps,
                  swipePageIndex: 0,
                }));
              }}
              >
                Thanks for blessing me, milords!
              </MDBBtn>
            </>
            )}

        ></M1Modal>

      </div>
    </div >
  )
}

export default PageTestingBuy

function getAnlyticsIdForNetwork(userChosenNetwork: UserChosenNetwork) {
  return getCommonNameOfNetwork(userChosenNetwork);
}

