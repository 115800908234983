export const eraQuestions : string[] = [
  "Why do these humans look like big babies?",
  "Our earthlings don´t speak, would you be willing to teach them how to?",
  "How do humans reproduce?",
  "We’ve learned most of them look like a human called “Elon Musk”? If you were part of the planet invasion project and know why this is the case please let us know",
  "We now know humans shouldn't be allowed to eat snake fang milk, are there any other foods they shouldn't be allowed to eat? Oh, and what should they eat?",
  "Do you have the contact id these humans call e-mail?",
]

export enum M2QuestionType {
  SHORT_QUESTION,
  LONG_QUESTION,
}
export interface M2QuestionItem {
  q: string,
  t: M2QuestionType,
}

export const eraQuestions2 : M2QuestionItem[] = [
  {
    "q" : "Write your human contact ID called e-mail...",
    "t" : M2QuestionType.SHORT_QUESTION
  },
  {
    "q" : "Write your web 3.0 handler a.k.a. wallet address...",
    "t" : M2QuestionType.SHORT_QUESTION
  },
  {
    "q" : "Write any useful information you may have or any questions you have for us...",
    "t" : M2QuestionType.LONG_QUESTION
  },
]