import React from 'react'
import AgostoLoader from '../components/AgostoLoader'
import Header from '../components/Header'
import ProvisionalNavigator from '../components/ProvisionalNavigator'
import Rectangle from '../components/Rectangle'
import TutorReviews from '../components/tutor/TutorReviews'

const PageTestingHome = () => {
  return (
    <div>
      {/* basic navbar example: */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Link</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Disabled</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container">

        <div className="row justify-content-center">
          {/*  d-flex align-items-center is to center vertically */}
          {/*  if using d-flex then use justify-content-center to center horizontally */}
          <div className="col-10 col-md-5 text-center d-flex align-items-center justify-content-center">

            <p className="h2">
              my stuff
            </p>
          </div>
          <div className="col-10 col-md-5">
            <Rectangle width={200} height={400}></Rectangle>
          </div>
        </div>
      </div>
      {/* <Header></Header> */}
      <ProvisionalNavigator></ProvisionalNavigator>
      {/* <TutorReviews></TutorReviews> */}
      {
        "home of all testing stuff"
      }
    </div>
  )
}

export default PageTestingHome
