import React from 'react'
import { useLocation } from 'react-router-dom';
import { eraTestPath } from '../App';
import { trackClick } from '../util/AnalyticsUtil'
import { isEarthling } from '../util/DevEnvUtil';
import EraDesktopHeaderMenu01 from './EraDesktopHeaderMenu01';
import RowSpacer from './RowSpacer'

interface MenuItemContent {
  id: string, // for analytics and id (in case no url is set)
  txt: string,
  url?: string,
}

export const menuItems = function (mintingIsLive: boolean) {
  const r : MenuItemContent[] = [
    {
      'id': 'what-is-it',
      'txt': 'intro',
      'url': '/#what-is-it',
    },
    {
      'id': 'faq',
      'txt': 'faqs',
      'url': '/#faq',
    },
    {
      'id': 'roadmap',
      'txt': 'roadmap',
      'url': '/#roadmap',
    },

  ];
  if (mintingIsLive) {
    r.push(
      {
        'id': 'buy',
        'txt': 'buy',
        'url': '/buy',
      },
    );
  }
  return r;
};

const DesktopHeaderMenu01 = ({ mintingIsLive }: {
  mintingIsLive: boolean
}) => {
  const location = useLocation();
  if (location.pathname === eraTestPath || isEarthling) {
    return (<EraDesktopHeaderMenu01 mintingIsLive={mintingIsLive}></EraDesktopHeaderMenu01>);
  }
  return (
    <>
      {/* // use fixed-top in case you want to fix to the top, 
    // note: in that case you must leave space for it above the icon */}
      <div className="container-fluid fixed-top">
        <div className="row hpeDesktopHeader" >
          <p className="p-0 m-0 cont d-none d-md-inline-flex justify-content-end">
            {menuItems(mintingIsLive).map(v => (
              <a key={v.id} href={(v.url == null ? "#" + v.id : v.url)} onClick={() => {
                trackClick('btn_nav_' + v.id)
              }}>
                {v.txt}
              </a>))}
          </p>
        </div>
      </div>

      {/* in case of fixed-top: */}
      <div className="d-none d-md-block container">
        <RowSpacer height={30}></RowSpacer>
      </div>

    </>
  );
}

export default DesktopHeaderMenu01
