import { isLocalhost, isProductionWebsite } from '../util/DevEnvUtil';
import { BigNumber } from "@ethersproject/bignumber";
import { UserChosenNetwork } from '../pages/PageTestingBuy';

// prices in wei
const maticPrice = BigNumber.from(10)
  .pow(18)
  .mul(180) // must be integer pls (BigNumber limitations)
  ;

const ethereumPrice = BigNumber.from(10)
  .pow(15) // NOTE: this is 1/1000 of ETH
  .mul(69) // must be integer pls (BigNumber limitations)
  ;

export const getPricePerPetInUnits = (userChosenNetwork: UserChosenNetwork) => {
  if (userChosenNetwork === UserChosenNetwork.Polygon) {
    return (isProductionWebsite ?
      maticPrice :
      BigNumber.from(10) // 10 wei
    );
  }
  return (isProductionWebsite ?
    ethereumPrice :
    BigNumber.from(10) // 10 wei
  );
};

export const pricePerPetInUsd = 360; // set one a little higher than 180 MATIC because 
// we have to make room for fees and crypto prices raising

export const maxPetsPerTr = 20;

export const suggestedGasPerNft = 489498; // from tests

export const getSuggestedGasPrice = (userChosenNetwork: UserChosenNetwork) => {
  if (userChosenNetwork === UserChosenNetwork.Polygon) {
    return BigNumber
      .from(10).pow(9)
      // .mul(20); // 20 gwei as gotten from https://www.polygongasstation.com/
      // .mul(50); // 50 gwei as seen in https://polygonscan.com/chart/gasprice 
      .mul(100); // 100 gwei as seen in opensea transactions
  }
  return BigNumber
    .from(10).pow(9)
    .mul(230); // gwei from https://ethgasstation.info/
}

export const catoshiPublicAddress = '0xca1046eD129234e61626F910a6d34E4D734986F3';


export const discordLink = 'https://discord.gg/n4BPC9AVpk';
export const termsUrl = "https://sites.google.com/meowthereum.com/humanpetelon/home";

// LAUNCH DATE:
// https://www.epochconverter.com/
// const unixTsTarget = 1632630600; //  Saturday, September 25, 2021 23:30:00 GMT-05:00
// const unixTsTarget = 1632614040; //  Saturday, September 25, 2021 18:54:00 GMT-05:00
// export const unixTsTarget = 1632444702; 
export const unixTsTarget = (isProductionWebsite ?
  1632621240 : //  Saturday, September 25, 2021 08:54:00 PM GMT-05:00 (06:54pm in LA/CA)
  0
  // Math.floor(Date.now() / 1000) + 15
);

export const sampleText = "The Human Pet Elon collection is made up by 10,987 unique NFTs who live in the post-cat-dominance world on the Polygon Blockchain (to prevent gas wars). \nThe collection will migrate after the presale to Ethereum or whichever blockchain the community decides.";