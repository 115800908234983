import React, { useEffect } from 'react'


const GenericShPage = ({source} : {
  source: string,
}) => {
  useEffect(() => {
    window.location.href = "https://humanpetelon.com?utm_source=" + encodeURIComponent(source) 
    + "&utm_medium=upcoming&utm_campaign=202109";
  }, []);
  
  return (
    <div>
      Loading the awesomeness... 😼
    </div>
  )
}

export default GenericShPage
