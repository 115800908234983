// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import { isProductionWebsite } from "./DevEnvUtil";

// firestore
// Required for side-effects
import "firebase/firestore";

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfigProduction = {
  apiKey: "AIzaSyDGKo29o3Sr_ms2I9jlRoW_JX0krqck9Tk",
  authDomain: "meowthereum.firebaseapp.com",
  projectId: "meowthereum",
  storageBucket: "meowthereum.appspot.com",
  messagingSenderId: "712760584664",
  appId: "1:712760584664:web:f0723aeda2c686cc876aad",
  measurementId: "G-EH5QCHN8FK"
};

const firebaseConfigTesting = {
  apiKey: "AIzaSyDGKo29o3Sr_ms2I9jlRoW_JX0krqck9Tk",
  authDomain: "meowthereum.firebaseapp.com",
  projectId: "meowthereum",
  storageBucket: "meowthereum.appspot.com",
  messagingSenderId: "712760584664",
  appId: "1:712760584664:web:8d064e4b32c5aada876aad",
  measurementId: "G-G4X4RD9XLE"
};

const firebaseConfig = (isProductionWebsite ? firebaseConfigProduction : firebaseConfigTesting);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Init analytics
export const fa = firebase.analytics();

// get firestore:
export const db = firebase.firestore();
