import { TransactionStatus, transactionErrored } from '@usedapp/core'
import { useEffect, useState } from 'react'

interface StatusAnimationProps {
  transaction: TransactionStatus
}

// RC: react component
export const TransactionStatusRC = ({ transaction }: StatusAnimationProps) => {
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)
  const [timer, setTimer] = useState(
    setTimeout(() => {
      void 0
    }, 1)
  )

  useEffect(() => {
    setShowTransactionStatus(true)
    clearTimeout(timer)

    if (transaction.status !== 'Mining') setTimer(setTimeout(() => setShowTransactionStatus(false), 20 * 1000))
  }, [transaction]); // if you include timer errors happen (infinite error generation in the console)

  return (
    <div>
      <div className="fs-6">
        {showTransactionStatus && transactionErrored(transaction) && (
          <p>🚫 {transaction?.errorMessage ?? ''}</p>
        )}
        {showTransactionStatus && transaction.status === 'Mining' && (
          <p>⌛ Transaction is being mined (wait for network confirmation)</p>
        )}
        {showTransactionStatus && transaction.status === 'Success' && (
          <p>💎 Transaction successful</p>
        )}
      </div>
    </div>
  )
}