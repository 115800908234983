import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit';
import React from 'react'
import '../style/MdbWrapper.scss';

const M1Modal = (props: {
  title: string,
  textContent?: string,
  modalBody: React.ReactElement,
  lowerRE?: React.ReactElement,
  onClose: () => void,
  showing_modal: boolean,
  closeOnBackdropClick?: boolean,
}) => {

  const textContent = props.textContent ?? "";

  return (
    <div className="mdbWrapperElite">
      {/* Modal for asking to connect wallet */}
      <MDBModal tabIndex='-1' staticBackdrop={ ! (props.closeOnBackdropClick ?? true) } show={props.showing_modal}

        // had to do this with the
        // next part, otherwise it generates problems (it is called infinitely for idk what reason)
        getOpenState={(isOpen: boolean) => {
          // console.log(isOpen);
          if (!isOpen && props.showing_modal) {
            props.onClose();
          }
        }}

      >
        <MDBModalDialog centered>
          <MDBModalContent className="ps-3 pe-3 pt-2">
            <MDBModalHeader>
              <MDBModalTitle>{props.title}</MDBModalTitle>

              {/* Next button was tested to work */}
              {/* <MDBBtn className='btn-close' color='none'
            onClick={() => toggleShowConnectWalletModal(false)}
            ></MDBBtn> */}
            </MDBModalHeader>
            <MDBModalBody className="pt-1 pb-3">
              <div className="row justify-content-center mb-3">
                {(textContent === "" ? "" : <p className="fs-6">{textContent}</p>)}
              </div>
              <div className="row justify-content-center mb-1">
                <div className="col-md-7">
                  {props.modalBody}
                </div>
              </div>
              {(props.lowerRE === null ? <div></div> : <div className="row justify-content-center mt-2">
                {props.lowerRE}
              </div>)}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>

      </MDBModal>

    </div>
  )
}

export default M1Modal
