import * as Sentry from "@sentry/react";
import { ChainId } from "@usedapp/core";
import { UserChosenNetwork } from "../pages/PageTestingBuy";
import { isProductionWebsite } from "./DevEnvUtil";

// https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
// https://metamask.zendesk.com/hc/en-us/articles/360057142392-Verifying-custom-network-information
// list of "official" chains: https://chainlist.org/
export interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18; // fixed in 18 because other numbers cause troubles in metamask and other things
  };
  rpcUrls: string[];
  blockExplorerUrls: string[]; // this is optional but I removed that so we enforce having it, each string must end in /
  iconUrls?: string[]; // Currently ignored.
}

const bscNetworkParams: AddEthereumChainParameter = {
  // chainId: `0x${(56).toString(16)}`,
  chainId: `0x38`,
  chainName: 'Binance Smart Chain Mainnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
  blockExplorerUrls: ['https://bscscan.com/'],
}

const polygonMumbaiNetworkParams: AddEthereumChainParameter = {
  chainId: '0x13881', // Hexadecimal version of 80001, prefixed with 0x
  chainName: "Matic(Polygon) Testnet Mumbai",
  nativeCurrency: {
    name: "tMATIC",
    symbol: "tMATIC",
    decimals: 18,
  },
  // rpcUrls: ["https://rpc-mumbai.matic.today"],
  rpcUrls: ["https://rpc-mumbai.maticvigil.com"], // gotten from https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/ on 10/11/2021
  blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
}

const polygonMainnetNetworkParams: AddEthereumChainParameter = {
  chainId: '0x89', // Hexadecimal version of 137
  // chainName: "Matic(Polygon) Mainnet",
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://polygon-rpc.com/"], // according to quickswap
  // rpcUrls: ["https://rpc-mainnet.maticvigil.com/"], // according to the polygon website and some videos
  // rpcUrls: ["https://rpc-mainnet.matic.network/"], // according to chainlist.org // TODO: test if this network works correctly
  blockExplorerUrls: ["https://polygonscan.com/"],
}

// gotten from my metamask installation
const ethereumMainnetNetworkParams: AddEthereumChainParameter = {
  chainId: '0x1',
  chainName: "Ethereum Mainnet",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  blockExplorerUrls: ["https://etherscan.io/"],
}

// gotten from my metamask installation
const rinkebyMainnetNetworkParams: AddEthereumChainParameter = {
  chainId: '0x4',
  chainName: "Rinkeby Test Network",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  blockExplorerUrls: ["https://rinkeby.etherscan.io/"],
}


const networkParamsMap: { [key: number]: AddEthereumChainParameter } = {
  [ChainId.Polygon]: polygonMainnetNetworkParams,
  [ChainId.Mumbai]: polygonMumbaiNetworkParams,

  [ChainId.Mainnet]: ethereumMainnetNetworkParams,
  [ChainId.Rinkeby]: rinkebyMainnetNetworkParams,

  [ChainId.BSC]: bscNetworkParams,
}


// if changing/testing with other network(blockchain), do it right here:
export const getNetworkToUseChainId = (userChosenNetwork: UserChosenNetwork) => {
  if (userChosenNetwork === UserChosenNetwork.Polygon) {
    return (isProductionWebsite ? ChainId.Polygon : ChainId.Mumbai)
  }
  return (isProductionWebsite ? ChainId.Mainnet : ChainId.Rinkeby)
}

export const getNetworkParamsToUse = (userChosenNetwork: UserChosenNetwork) => {
  return networkParamsMap[getNetworkToUseChainId(userChosenNetwork)];
}

export const getEthereum = () => {
  // @ts-ignore
  return (window as WindowChain).ethereum;
}

export async function eth_chainId() {
  const ethereum = getEthereum();
  return await ethereum.request({ method: 'eth_chainId' }); // string
}

export const noMetamaskAlertText = 'MetaMask is not installed 🙀. Please visit https://metamask.io/download.html to install it. '
+ 'If you are on a mobile device you must open this website within your wallet browser, not your regular browser.';

export async function checkCurrentNetwork(userChosenNetwork : UserChosenNetwork) {
  const chainParameters: AddEthereumChainParameter = getNetworkParamsToUse(userChosenNetwork);
  // check the current network prior to the change, so we can 
  // show certain explanation
  try {
    const n = await eth_chainId();
    if (n === chainParameters.chainId) {
      // we are in the correct network already
      return true;
    }
  } catch (error) {
    Sentry.captureException(error);
    alert("Error while querying network 🙀");
  }
  return false;
}

export async function metamaskCheckNetworkAndTryToChangeIt(
  userChosenNetwork: UserChosenNetwork,
) {

  const chainParameters: AddEthereumChainParameter = getNetworkParamsToUse(userChosenNetwork);

  // Check if MetaMask (or similar ethereum compatible wallet) is installed
  // MetaMask injects the global API into window.ethereum
  const ethereum = getEthereum();

  // https://docs.metamask.io/guide/getting-started.html#basic-considerations
  if (ethereum) {

    const ccn = await checkCurrentNetwork(userChosenNetwork);
    if (ccn === true) return true;

    try {
      // TODO: it would be great to check if the nework is installed already
      // (without trying to change), so we can explain better to the user 
      // (I mean: 
      // In case the wallet has the network installed just say
      // "Switch network" instead of "Add and switch network")
      // maybe look here: https://metamask.github.io/api-playground/api-documentation/
      // but I don't think it is a priority for now

      // check if the chain to connect to is installed and try to switch to it
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{
          chainId:
            chainParameters.chainId
        }], // chainId must be in hexadecimal numbers
      });

      return true;

    } catch (switchErrorCaught) {
      const switchError = switchErrorCaught as any;

      // in case of network not added to wallet yet
      if (checkForUnrecognizedOrNotAddedNetwork(switchError)) {
        try {

          await ethereum.request({
            method: 'wallet_addEthereumChain', // this also switches to that network after adding it
            // if the user adds the network but rejects the switch to it, then 
            // this doesnt throw any error 
            params: [
              chainParameters,
            ],
          });
          // console.log("finished adding network"); // shows always after adding & switching (or rejecting switching)
          
          // making sure the user actually changed to the network requested
          const checkResult = await checkCurrentNetwork(userChosenNetwork);
          if (checkResult === true) return true;
          return false;

        } catch (addError) {
          const addErrorAny = addError as any;
          if (addErrorAny.code === 4001) { // user rejected
            ;
          } else { // other
            Sentry.captureException(addError);
            alert("Error while adding network " + chainParameters.chainName + " 🙀");
          }
        }
      } else if (switchError.code === 4001) { // user rejected
        ;
      } else {
        Sentry.captureException(switchError);
        alert("🙀 Error while switching to network: " + chainParameters.chainName 
        + ". If you are on mobile check that your Metamask app is updated (must be at least the version 3.3.0)");
      }
    }
  } else {
    // if no ethereum then MetaMask is not installed
    alert(noMetamaskAlertText);
  }
}


function checkForUnrecognizedOrNotAddedNetwork(switchError: any) {
  console.log(switchError);
  // This error code indicates that the chain has not been added to MetaMask
  // if it is not, then install it into the user MetaMask
  if (switchError.code === 4902) {
    return true;
  }

  const message = (switchError.message as string) ?? "";
  const testString = "Unrecognized chain ID";
  if (message.toLowerCase().includes(testString.toLowerCase())) {
    return true;
  }

  return false;
}