import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import PageTestingHome from './pages/PageTestingHome';
import PageUpdateStateTest from "./pages/PageUpdateStateTest";
import PageLogin from "./pages/PageLogin";
import PageMDBTest from "./pages/PageMDBTest";
import { useEffect, useState } from "react";
import PageHome from "./pages/PageHome";
import PageTestingBuy from "./pages/PageTestingBuy";
import smartlookClient from 'smartlook-client'
import { isEarthling, isProductionWebsite } from "./util/DevEnvUtil";
import PageTestingSwiper from "./pages/PageTestingSwiper";
import ShPageRarity from "./pages/shortcuts/ShPageRarity";
import ShPageNftcalendar from "./pages/shortcuts/ShPageNftcalendar";
import ShPageNftInstagram from "./pages/shortcuts/ShPageNftInstagram";
import ShPage2Nft from "./pages/shortcuts/ShPage2Nft";
import GenericShPage from "./pages/shortcuts/GenericShPage";
import DesktopHeaderMenu01 from "./components/DesktopHeaderMenu01";
import PageEraHome from "./pages/PageEraHome";
import { unixTsTarget } from "./util/Constants";

export const eraTestPath = "/earthlingrescuealliance";

interface State {
  mintingIsLive: boolean,
}

function getMintingIsLive() {
  return Math.floor(Date.now() / 1000) > unixTsTarget;
}

function App() {

  const initialState: State = {
    mintingIsLive: getMintingIsLive(),
  }

  const [state, setState] = useState(initialState);

  // runs when this component loads:
  useEffect(() => {
    // initializations
    if (isProductionWebsite) {
      smartlookClient.init('a0e4f1f06a46f930fd24b74fb92e54b2388d5724');
    }

    // minting live check:
    const interval = setInterval(() => {
      setState(ps => ({
        ...ps,
        mintingIsLive: getMintingIsLive(),
      }));
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  const r = Math.random(); // real en [0, 1)

  let home = null;
  if (isEarthling) {
    home = <PageEraHome mintingIsLive={state.mintingIsLive}></PageEraHome>;
  } else {
    home = <PageHome mintingIsLive={state.mintingIsLive}></PageHome>;
  }

  return (
    <Router
    // basename="/tunnelJosue" // con esto los assets aún se cargan en el path '/'
    // basename={process.env.PUBLIC_URL}
    >
      <div>
        <DesktopHeaderMenu01 mintingIsLive={state.mintingIsLive}></DesktopHeaderMenu01>
        
        {/* <Header username={username}></Header>
        <MyPayments paymentsRes={paymentsRes}></MyPayments>
        <Footer></Footer> */}

        {/* <ProvisionalNavigator></ProvisionalNavigator> */}

        <Switch>
          <Route path="/PageMDBTest">
            <PageMDBTest></PageMDBTest>
          </Route>
          <Route path="/PageTestingSwiper">
            <PageTestingSwiper></PageTestingSwiper>
          </Route>
          <Route path="/PageTestingBuy">
            <PageTestingBuy mintingIsLive={state.mintingIsLive}></PageTestingBuy>
          </Route>
          <Route path="/buy">
            <PageTestingBuy mintingIsLive={state.mintingIsLive}></PageTestingBuy>
          </Route>
          <Route path="/login-test">
            <PageLogin></PageLogin>
          </Route>
          <Route path="/testing-home">
            <PageTestingHome></PageTestingHome>
          </Route>
          <Route path="/test-update-state">
            <PageUpdateStateTest></PageUpdateStateTest>
          </Route>

          {/* Rarity tools referral shortlink */}
          <Route path="/hr">
            <ShPageRarity></ShPageRarity>
          </Route>
          {/* NftCalendar referral shortlink */}
          <Route path="/hn">
            <ShPageNftcalendar></ShPageNftcalendar>
          </Route>
          {/* @nft instaggram referral shortlink */}
          <Route path="/nftbuy">
            <ShPageNftInstagram></ShPageNftInstagram>
          </Route>
          {/* 2 Nft referral shortlink */}
          <Route path="/ho">
            <ShPage2Nft></ShPage2Nft>
          </Route>
          {/* NftCatcher */}
          <Route path="/nc">
            <GenericShPage source="nc"></GenericShPage>
          </Route>
           {/* Nft(verified) discord */}
           <Route path="/nv">
            <GenericShPage source="nv"></GenericShPage>
          </Route>
          {/* /r/NFT reddit */}
          <Route path="/rn">
            <GenericShPage source="rn"></GenericShPage>
          </Route>
          {/* polygonscan */}
          <Route path="/psc">
            <GenericShPage source="psc"></GenericShPage>
          </Route>



          <Route path="/humanpetelonoldhome">
            <PageHome mintingIsLive={state.mintingIsLive}></PageHome>
          </Route>

          <Route path={eraTestPath}>
            <PageEraHome mintingIsLive={state.mintingIsLive}></PageEraHome>
          </Route>

          <Route path="/">
            {home}
          </Route>

          <Route path="/scptest">
            
          </Route>

        </Switch>
      </div>
    </Router>
  );
}

export default App;
