import React from 'react'

const Circle = (props : {
  oneDim: number,
}
) => {
  return (
    <div className="hpeCircle" style={{
      width: props.oneDim,
      height: props.oneDim,
    }}>
    </div>
  )
}

export default Circle
