import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChainId, Config, DAppProvider } from '@usedapp/core'
import { isLocalhost, isProductionWebsite } from './util/DevEnvUtil';
import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';


const config: Config = {
  readOnlyChainId: (isLocalhost? ChainId.Mumbai : ChainId.Polygon), // ChainId of a chain you want to connect to by default in a read-only mode
  readOnlyUrls: {
    [ChainId.Polygon]: 'https://polygon-mainnet.infura.io/v3/b05b88da948c4c03997262dee3a7ef28',
    [ChainId.Mumbai]: 'https://polygon-mumbai.infura.io/v3/b05b88da948c4c03997262dee3a7ef28',
    [ChainId.Mainnet]: 'https://mainnet.infura.io/v3/b05b88da948c4c03997262dee3a7ef28',
    [ChainId.Rinkeby]: 'https://rinkeby.infura.io/v3/b05b88da948c4c03997262dee3a7ef28',
  },
  supportedChains: [
    (isLocalhost? ChainId.Mumbai : ChainId.Polygon),
    (isLocalhost? ChainId.Rinkeby : ChainId.Mainnet),
  ],
  pollingInterval: 20 * 1000, // in millis (when tested thid DIDNT CHANGE THE REFRESH TIME), just working with the default tested with polygon seems to be 12 or 15 seconds
}

Sentry.init({
  dsn: "https://0c4fa234075c4ec1bd0c5c68d8316685@o991237.ingest.sentry.io/5948224",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: (isLocalhost? 1.0 : 1.0),

  enabled: isProductionWebsite,
});


ReactDOM.render(
  <React.StrictMode>
    {/* Disabled for now */}
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
