import {
  ERC20Interface,
  useContractCall,
  useContractCalls,
} from '@usedapp/core'
import { Interface } from '@ethersproject/abi'
import ERC721abi from '../abi/ERC721.json'

export function useTokensBalance(tokenList: string[], account?: string | null) {
  return useContractCalls(
    tokenList && account
      ? tokenList.map((token) => ({
        abi: ERC20Interface,
        address: token, // "0x" string 
        method: 'balanceOf',
        args: [account],
      }))
      : []
  )
}

const ERC721Interface = new Interface(ERC721abi);
export function useErc721Balance(tokenAddress: string, account?: string | null) {
  return useContractCall(
    account
      ? {
        abi: ERC721Interface,
        address: tokenAddress, // "0x" string 
        method: 'balanceOf',
        args: [account],
      }
      : null
  )
}

