import React from 'react'

const M1Balance = ({ nftBalance, account }: {
  nftBalance: any,
  account: string | null | undefined,
}) => {
  const balance = parseInt(nftBalance ?? 0);
  // const balance = (true ? 1: 0);
  const zeroText = "You currently don't own any Human Pet Elons";
  const oneText = "You currently own one Human Pet Elon";
  const manyText = "You currently own " + balance + " Human Pet Elons";

  let finalText = "";
  if (balance === 0) {
    finalText = zeroText;
  } else if (balance === 1) {
    finalText = oneText;
  } else {
    finalText = manyText;
  }
  return (
    <div>
      {/* in case the account is null then we don't don't have anything to show */}
      {(account == null ? "" : <p className="h6">{(nftBalance == null ? "⌛ Loading how many you got..." : finalText)}</p>)}
    </div>
  )
}

export default M1Balance
