import { CSSProperties, useEffect, useState } from "react";

interface State {
  isPurple: boolean,
}

const DiscordButton = (props: {
  title?: string,
  onClick?: () => void,
  isOnHero?: boolean,
  disabled?: boolean,
}) => {

  const initialState: State = {
    isPurple: true,
  }
  const [state, setState] = useState(initialState);
  // runs when this component loads:
  useEffect(() => {
    // minting live check:
    const interval = setInterval(() => {
      setState(ps => ({
        ...ps,
        isPurple: !ps.isPurple,
      }));
    }, 2.5 * 1000);
    return () => clearInterval(interval);
  }, []);

  const isOnHero = props.isOnHero ?? false;
  const st: CSSProperties = {};

  if (isOnHero) {
    // st.backgroundColor = state.isPurple ? 'purple' : 'black';
    st.transition = "background-color 1s ease-in-out";
  }

  return (
    <div>
      <button
        disabled={props.disabled ?? false}
        className={"btn btn-primary jdiscord " + (isOnHero ? (state.isPurple ? "heroPurple" : "heroBlack") : "")}
        style={st}
        onClick={props.onClick}
      >{props.title ?? "Join our discord"}</button>
    </div>
  )
}

export default DiscordButton
