import React, { useEffect, useState } from 'react'
// for swiper:
import 'swiper/swiper-bundle.css';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import HeroSocials from '../components/HeroSocials'
import RowSpacer from '../components/RowSpacer'
import { trackClick } from '../util/AnalyticsUtil'
import { minUnitsToNormal } from '../util/CryptoConvUtil'
import { LeftHpeMobileMenuButton01 } from '../components/LeftHpeMobileMenu01';
import { getPricePerPetInUnits, termsUrl } from '../util/Constants';
import { UserChosenNetwork } from './PageTestingBuy';
import { getNetworkParamsToUse } from '../util/MetamaskUtil';
import M1Questions from '../components/M1Questions';
import { LeftEraMobileMenuItems01 } from '../components/LeftEraMobileMenu01';
import { eraQuestions } from '../util/EraQuestions';
import M2Questions from '../components/M2Questions';
import DiscordButton from '../components/DiscordButton';
import { openWhatsapp } from '../util/OpenWhatsapp';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

// will return mumbai during testing and polygon mainnet in production
const polygonNetworkParams = getNetworkParamsToUse(UserChosenNetwork.Polygon);


const nNftsString = "10,987";

function getGalleryImageUrl(v: string) {
  return "tp_assets/images/" + v + ".webp";
}

const galleryImages = [
  "gallerywebp/cowboylonRM1",
  "gallerywebp/crazyBeastElonPortal",
  "gallerywebp/devilSkullWindows",
  "gallerywebp/duckYellowPlain",
  "gallerywebp/elonchuTattedIce",
  "gallerywebp/elonClownSaiyanNasa",
  "gallerywebp/ElonhattanOriginight",
  "gallerywebp/elonVenomWindows",
  "gallerywebp/goofyElonPortalBlue",
  "gallerywebp/higherThanHellonMoon",
  "gallerywebp/hippieAngelRainbow",
  "gallerywebp/ogrelonTatooine",
  "gallerywebp/savageFireIce",
  "gallerywebp/spaceGangSquidPlainBrown",
  "gallerywebp/thermalTattedBrainOutlonBWHeaven",
];

interface RoadmapUnlock {
  perc: string,
  txt: string | React.ReactElement,
}

interface FaqItem {
  question: string,
  answer: string | React.ReactElement,
  id?: string,
}


interface State {
  countdown: any,
  heroGalleryIndex: number,
}

const PageEraHome = ({ mintingIsLive }: {
  mintingIsLive: boolean
}) => {
  return (<div className='container'>
    <div className="row justify-content-center text-center">
    <br></br>
    We are building this with you...
    <br></br>
    <br></br>
      <DiscordButton
        isOnHero={true}
        title={"Join the movement"}
        onClick={() => {
          trackClick("btn_buy_11");
          openWhatsapp("Hi, I was checking out the ERA website and I want to join the movement!!");
        }}></DiscordButton>
    </div>

    <br />

  </div>);

  const initialState: State = {
    countdown: null,
    heroGalleryIndex: Math.floor(Math.random() * galleryImages.length),
  }

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const interval = setInterval(() => {
      setState(ps => ({
        ...ps,
        heroGalleryIndex: (ps.heroGalleryIndex + 1) % galleryImages.length,
      }));
    }, 6 * 1000);
    return () => clearInterval(interval);
  }, []);

  const roadmap: RoadmapUnlock[] = [
    {
      "perc": "5",
      "txt": "We pay our catnip dealer.",
    },
    {
      "perc": "10",
      "txt": "We set up our discord and verify owners.",
    },
    {
      "perc": "20",
      "txt": "You vote for whether you want us" +
        " to move to another blockchain and what blockchain.",
      //  + " or you want us to focus on building the humangotchi.",
    },
    {
      "perc": "30",
      "txt": "We airdrop 10 pets to random devoted Pet Holders.",
    },
    {
      "perc": "40",
      "txt": "We open a Merch Store. And yes, we will include leashes for you to practice being our pets.",
    },
    {
      "perc": "50",
      "txt": (<span>
        You decide what kind of game you want us to develop, choosing from the following options:
        <br></br>
        <br></br>
        <ul>
          <li>Humangotchi</li>
          <li>The ability to breed more generations</li>
          <li>Location based game (like Pokémon Go. Some of our humans previously developed & managed a gps-based app with half a million downloads)</li>
          <li>Turn Based game</li>
          <li>Racing game (like zed.run)</li>
        </ul>
      </span>),
    },
    {
      "perc": "60",
      "txt": "Human Pet Holders merch giveaways.",
    },
    // {
    //   "perc" : "60",
    //   "txt" : "You will be able to vote to choose a partnership with other projects or brands so we can release more merch.",
    // },
    {
      "perc": "80",
      "txt": "We unleash the “Genetically Modified Elons” to random Pet Holders.",
    },
    {
      "perc": "90",
      "txt": (
        <span>
          We giveaway 5 ETH for our lucky contests winners. Exclusive for Human Pet Elon hodlers.
          <br></br>
          We reveal which pets already include a cat owner NFT and which hold some of our upcoming $MEOW coin.
        </span>
      ),
    },
    {
      "perc": "100",
      "txt": (<span>
        We start with our plan of world domination preparing new ways to lure you into
        <span className="ms-1 me-1" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
          working for us
        </span>
        helping to further develop this project. We will keep talking with you, early human devotees,
        to polish the details for the Meowthereum Universe.
      </span>),
    },
  ];

  const faqContent: FaqItem[] = [
    {
      "question": "What is a Human Pet Elon?",
      "answer": "A pet in the metaverse that doubles as a reminder and acceptance of what the rightful place of you humans actually will be.",
    },
    {
      "question": "Was any human harmed during the production of these NFTs?",
      "answer": "None of our humans were physically harmed. Won’t comment anymore without our lawyers.",
    },
    // {
    //   "question": "Wen Petz?",
    //   "answer": "September 25th.",
    // },
    {
      "question": "How much does a Human Pet Elon cost?",
      "answer": minUnitsToNormal(getPricePerPetInUnits(UserChosenNetwork.Polygon), UserChosenNetwork.Polygon) + " " + polygonNetworkParams.nativeCurrency.symbol + " (about 0.069 ETH, at the moment this was written). You can pay with ethereum, polygon or other methods.",
    },
    {
      "question": "When will I be able to see how my NFT looks like?",
      "answer": "TBD.",
    },
    // {
    //   "question": "All of the Human Pet Elons will be put on sale?",
    //   "answer": "No. 50 random pets are being withheld from the sale. These will be used for giveaways and rewards of our contests.",
    // },
    {
      "question": "How can I get a Human Pet Elon?",
      "answer": "Human Pet Elons will be available for purchase on our website. At the time of purchase, a randomly selected Human Pet Elon will be minted on the blockchain and delivered to your wallet.",
    },
    {
      "question": "How were the Human Pet Elons created?",
      "answer": "Each Human Pet Elon is algorithmically generated by combining" +
        " 700+ unique traits with varying rarities in more than 15 categories",
    },
    {
      "question": "What's the Human Pet Elon's future?",
      "answer": (<div>
        The owners of the Pets will have the right to vote on what we do next, as well as future rewards.
        Possibilities are not locked up yet, but include location or turn-based combat games.
        <br></br>
        More info in our <a href="#roadmap" onClick={() => {
          trackClick("btn_roadmap_from_faq");
        }}>Roadmap Unlocks section</a>.
      </div>)

    },
    {
      "question": "How do I actually purchase a Human Pet Elon?",
      "answer": (<div>
        <ol>
          <li><a href="https://metamask.io/download" onClick={() => {
            trackClick('btn_d_metamask');
          }}>Download the Metamask extension</a> for Google Chrome and set up your wallet.</li>
          <li>Click on the Buy button, follow the steps and then approve the transaction on Metamask.</li>
          <li>If you don't have enough coins, buy {polygonNetworkParams.nativeCurrency.symbol} on an exchange (you know, like Coinbase, Binance or others) and send it to your Metamask Wallet Public Address.</li>
        </ol>


      </div>),
    },
    {
      "question": "Why Polygon?",
      "answer": (<div>
        To prevent gas wars. You know, so you don’t have to do
        stuff <a href="https://twitter.com/thedigitalvee/status/1404485588699463686?s=20">like this</a> spending
        hundreds of dollars.
        <br></br>
        We also accept other payment methods (like ETH on ethereum).
        <br></br>
        Anyway, at a certain <a href="#roadmap" onClick={() => {
          trackClick("btn_roadmap_from_why_polygon");
        }}>point on our roadmap</a> you get
        to decide if you want to be able to transfer
        your NFTs to the ethereum blockchain or you want us to stay on Polygon and focus on other things.
      </div>),
      "id": "why-polygon",
    },
    // {
    //   "question": "Need to contact Us?",
    //   "answer": "",
    // },
  ];

  const f = .35; // if you change this remember to also change it on the tsx

  const galleryImageSize = window.screen.width >= 720 ? 231 : 372 * f;
  const galleryImageSizeSpace = 30 / 372 * galleryImageSize;


  const saleIsLive = mintingIsLive;

  const title01 = 'Earthling\nRescue\nAlliance';

  const heroRightIconSize = 240;

  document.title = 'Home | Earthling Rescue Alliance';

  return (
    <div>
      <div className="container" id="what-is-it" style={{
        position: 'relative',
      }}>
        <RowSpacer height={40 * f}></RowSpacer>
        <RowSpacer className="d-none d-md-block" height={75 * f}></RowSpacer>

        {/* Main hero row, reverse column in case mobile */}
        <div className="row 
        d-flex flex-column-reverse flex-sm-row
        align-items-center justify-content-center">

          {/* dummy */}
          <div className="col-12 col-sm-4">
          </div>

          {/* title */}
          <div className="col-12 col-sm-4">
            <div className="row justify-content-center text-center">
              <p className="h2 title" style={{
                whiteSpace: 'pre-line',
              }}>
                {title01}
              </p>
            </div>
          </div>

          {/* icon */}
          <div className="col-12 col-sm-4">
            <div className="text-center text-sm-end pb-1 pb-sm-0">
              <img className="" src="tp_assets/images/ERA---logo.png" alt="logo" style={{
                width: heroRightIconSize * f,
                height: heroRightIconSize * f,
              }}></img>
            </div>
          </div>
        </div>


        <RowSpacer className="d-block d-lg-none" height={75 * f}></RowSpacer>
        <RowSpacer className="d-none d-md-block" height={200 * f}></RowSpacer>

        {/* mobile menu button, requires "position: relative" on the parent in order to work */}
        <LeftHpeMobileMenuButton01></LeftHpeMobileMenuButton01>
      </div>

      {/* mobile menu items */}
      <LeftEraMobileMenuItems01 mintingIsLive={mintingIsLive}></LeftEraMobileMenuItems01>


      {/* body */}
      <div className="container px-5">

        {/* row 01 */}
        <div className="row d-flex gx-5">

          {/* image */}
          <div className="col-12 col-md-6 d-flex align-items-center">
            <div className="row justify-content-center pb-3 pb-md-0">
              {/* <Rectangle width={180} height={100}></Rectangle> */}
              <img className="img-fluid" src="tp_assets/images/ERA-EandM5.webp" alt="ERA-EandM5">
              </img>
            </div>
          </div>

          {/* text */}
          <div className="col-12 col-md-6 d-flex align-items-center">
            <div className="row justify-content-center">
              <p style={{
                whiteSpace: 'pre-line',
              }}>
                It was the year 2084 and Earth was done.  I mean, as the old human saying goes “gg well played”. Temperature was higher than “hell”, no water, no crops, people were dying and the only topic of conversation - or rather the only thing in people’s heads was getting a ticket on one of the ships.
                <br />
                <br />
                Desperation was real. I read of a guy who despite having invested all his Shiba savings into a 10 square foot house on the moon he was also miserable.The Earth was expected to implode in ~15 months and what the fuck was the moon gonna go around?
              </p>
            </div>
          </div>

        </div>

        <RowSpacer className="d-block d-lg-none" height={45}></RowSpacer>

        {/* reversing order in case lower than md, this  */}
        <div className="row d-flex gx-5 flex-column-reverse flex-md-row">

          {/* text */}
          <div className="col-12 col-md-6  d-flex align-items-center">
            <div className="row justify-content-center">
              <p style={{
                whiteSpace: 'pre-line',
              }}>
                Fast forward, November 2085 and word on the space says 20k ships left, but apparently not all ships had humans in them. With 3000 people per ship, they housed roughly 70% of the already shrunken earthling population.
                <br />
                <br />
                The rest of the ships had other goals, some were intended to be earthling factories with the mission to go to uninhabited  earth-like exo-planets and invade them, to subsequently sell the land plots to the non-artificial earthlings who’ll have to escape again after they deplete the resources of Earth 2.0 and so on (very primitive capitalism).
              </p>
            </div>
          </div>

          {/* image */}
          <div className="col-12 col-md-6  d-flex align-items-center">
            <div className="row justify-content-center pb-3 pb-md-0">
              {/* <Rectangle width={180} height={100}></Rectangle> */}
              <img className="img-fluid" src="tp_assets/images/ERA-Spaceship-3.webp" alt="ERA-EandM5">
              </img>
            </div>
          </div>
        </div>

        <RowSpacer className="d-block d-lg-none" height={45}></RowSpacer>

        {/* row 03 */}
        <div className="row d-flex gx-5">

          {/* image */}
          <div className="col-12 col-md-6 d-flex align-items-center">
            <div className="row justify-content-center pb-3 pb-md-0">
              {/* <Rectangle width={180} height={100}></Rectangle> */}
              <img className="img-fluid" src="tp_assets/images/ERA-Cat-and-Meow.webp" alt="ERA-Cat-and-Meow.webp">
              </img>
            </div>
          </div>

          {/* text */}
          <div className="col-12 col-md-6 d-flex align-items-center">
            <div className="row justify-content-center">
              <p style={{
                whiteSpace: 'pre-line',
              }}>
                One of these ships arrived in my hometown, Caturn (catnip now legal!) a planet inhabited by what humans see as giant cats (my kind). We noticed these cute earthlings and couldn't help but fall in love and take care of them. I rescued 7.
                <br />
                <br />
                To prevent our beloved pets’ extinction we formed the “Earthling Rescue Alliance” to get external help and are using old Web 3.0 and the Interplanetary File System (IPFS) to try to connect with humans anywhere between 2017 and present day 3015 to do so.
              </p>
            </div>
          </div>

        </div>

        <RowSpacer height={45}></RowSpacer>

        <div id="help-us" className="row justify-content-center fw-bold">
          <div className="col-12 col-md-7">
            <div className="row">
              <p>You can help us by adopting a cute earthling and/or answering some of the many questions we have: </p>
            </div>

            <RowSpacer height={10}></RowSpacer>

            <div className="row text-start text-md-left">
              <p>
                - Why do these humans look like big babies?<br />
                - Our earthlings don't speak, would you be willing to teach them how to?<br />
                - How do humans reproduce?<br />
                - We've learned most of them look like a human called
                "Elon Musk"? If you were part of the Planet Invasion Project <br />
                - What should we be feeding our humnas?<br />
                <br />
                Yours,<br />
                Juanchito<br />
                <br />
                PS: Feel free to send questions and stuff
                <br />
              </p>
            </div>
            <RowSpacer height={10}></RowSpacer>
          </div>
        </div>


      </div>

      {/* questions body */}
      <div className="container px-3 px-sm-1">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <M2Questions formContentsId="eraq2"></M2Questions>
          </div>
        </div>

        <RowSpacer height={45}></RowSpacer>
      </div>

      {/* footers */}
      {/* NOTA: siempre colocar las cosas dentro de containers, sino empiezan a aparecer espacios extras indeseables */}
      <div className="container-fluid">
        <div className="row">
          <div className="rayita"></div>
        </div>

        <RowSpacer height={40 * f}></RowSpacer>

        {/* footer1 */}
        <div className="row d-flex align-items-center justify-content-center">

          <div className="col-12 col-md-4">

            <div className="row d-flex justify-content-center">
              <div className="col-9 col-md-6 justify-content-center justify-content-md-start text-center text-md-start">
                <p className="h7 title">Get on the list</p>
                {/* Begin Mailchimp Signup Form */}
                <div id="mc_embed_signup">
                  <form
                    onSubmit={(e) => {
                      /* do what you want with the form */
                      // document.getElementById("theForm").submit();

                      const email = (document.getElementById('mce-EMAIL') as any).value as string;
                      const attr = {
                        email: email,
                      };

                      // console.log(attr); // tested showing the right values
                      trackClick('btn_home_mc_email', attr);

                      // You must return false to prevent the default form behavior (but mailchimp works anyway as tested)
                      return false;
                    }}
                    action="https://meowthereum.us5.list-manage.com/subscribe/post?u=b82cd6e52084c44848b115815&id=3d4ad6f52a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <div id="mc_embed_signup_scroll" className="justify-content-center justify-content-md-start text-center text-md-start" style={{
                      display: 'flex',
                      marginTop: 45 * f,
                    }}>
                      <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_b82cd6e52084c44848b115815_3d4ad6f52a" tabIndex={-1} defaultValue="" /></div>
                      <div className="clear">
                        <input type="submit" value="→" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                    </div>
                  </form>
                </div>
                {/*End mc_embed_signup*/}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
            <img className="" src="tp_assets/images/ERA---logo.png" alt="logo in gif" style={{
              marginTop: 93 * f,
              marginBottom: 70 * f,
              width: 254 * f,
              height: 254 * f,
            }}></img>
          </div>

          <div className="col-12 col-md-4">
            <div className="row d-flex align-items-center justify-content-center text-center">
              <div className="col-12">
                <HeroSocials analyticsId={"footer"}></HeroSocials>
              </div>
              <div className="col-12">
                <a href={termsUrl}>Terms and Conditions</a>
              </div>
            </div>
          </div>

        </div>

        <RowSpacer height={60 * f}></RowSpacer>

        <div className="row p-0 justify-content-center copyright">
          <div className="col-11 text-center">
            <RowSpacer height={85 * f}></RowSpacer>
            Part of the Meowthereum Universe - 2021 - All Rights Reserved
            <RowSpacer height={197 * f}></RowSpacer>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-10 col-md-5 text-center">
            <p className="h2">
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageEraHome
