import React from 'react';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import MetaTags from 'react-meta-tags';

const PageMDBTest = () => {
  return (

    <MDBContainer fluid>
      {/* These og tags also don't work with facebook, it seems that server rendering would be needed
      https://www.google.com/search?q=facebook+sharing+debugger+doesnt+recognize+og+meta+tags+changed+with+javascript&oq=facebook+sharing+debugger+doesnt+recognize+og+meta+tags+changed+with+javascript&aqs=chrome..69i57j69i64.17007j0j9&sourceid=chrome&ie=UTF-8
       */}
      <MetaTags>
        <meta property="og:title" content="test01" />
        <meta property="og:description" content="test01" />
        {/* <meta property="og:image" content={process.env.PUBLIC_URL + "/tp_assets/images/hpeProjectBanner.png"} /> */}
      </MetaTags>

      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <div className='text-center'>
          <img
            className='mb-4'
            src='https://mdbootstrap.com/img/logo/mdb-transparent-250px.png'
            style={{ width: 250, height: 90 }}
          />
          <h5 className='mb-3'>
            Thank you for using our product. We're glad you're with us.
          </h5>
          <p className='mb-3'>MDB Team</p>
          <MDBBtn
            tag='a'
            href='https://mdbootstrap.com/docs/standard/getting-started/'
            target='_blank'
            role='button'
          >
            Start MDB tutorial
          </MDBBtn>
        </div>
      </div>
    </MDBContainer>
  )
}

export default PageMDBTest
