import React from 'react'
import { trackClick } from '../util/AnalyticsUtil';
import { discordLink } from '../util/Constants';

const HeroSocials = ({analyticsId: anlyticsId} : {
  analyticsId : string,
}) => {
  return (
    <div className="heroSocials">

      {/* <button type="button" className="btn btn-primary btn-floating btnSocialsHero" onClick={() => {
        trackClick('btn_discord_' + anlyticsId);
        window.open(discordLink, '_blank')?.focus();
      }}>
        <i className="fab fa-discord" />
      </button> */}


      {/* <button type="button" className="btn btn-primary btn-floating btnSocialsHero" onClick={() => {
        trackClick('btn_instagram_' + anlyticsId);
        window.open('https://www.instagram.com/hpe_nft', '_blank')?.focus();
      }}>
        <i className="fab fa-instagram" />
      </button>



      <button type="button" className="btn btn-primary btn-floating btnSocialsHero" onClick={() => {
        trackClick('btn_twitter_' + anlyticsId);
        window.open('https://twitter.com/HPE_NFT', '_blank')?.focus();
      }}>
        <i className="fab fa-twitter" />
      </button> 
      */}
      
    </div>
  )
}

export default HeroSocials
